import React, { Fragment } from 'react'
import TeamSlider from '../../_components/TeamSlider/TeamSlider'
import { Row, Col } from 'react-bootstrap'

export default function About() {
  return (
    <Fragment>
      {/* Meet The Team */}
      <Row style={{ overflow: 'hidden' }}>
        <Col
          xs={{ span: 12, order: 'second' }}
          lg={{ span: 2, order: 'first' }}
        >
          <div className='meet__the__team__dot--container'>
            <div className='meet__the__team__dot--two' />
            <div className='meet__the__team__dot--one' />
          </div>
        </Col>
        <Col
          xs={{ span: 12, order: 'first' }}
          lg={{ order: 'second' }}
          className='meet__the__team'
        >
          <Row>
            <Col xs={{ span: 12, offset: 1 }} lg={{ span: 4 }}>
              <div className='meet__the__team--subtitle'>WE ARE AXS HEALTH</div>
              <div className='meet__the__team--title'>
                Meet the{' '}
                <span className='meet__the__team--highlight'>team</span>
              </div>
            </Col>
            <Col
              xs={{ span: 12, offset: 0 }}
              lg={{ offset: 2 }}
              className='meet__the__team--team-slider'
            >
              <TeamSlider />
            </Col>
          </Row>
        </Col>
      </Row>

      {/* About AxS */}
      <Row className='about__axs'>
        <Col>
          <div className='container mx-auto'>
            <Row>
              <Col lg={{ span: 6, offset: 2 }}>
                <div className='about__axs--title'>ABOUT AXS HEALTH</div>
                <div className='about__axs--detail'>
                  Our platform offers digital patient registration,
                  telemedicine, and patient communication solutions, enabling
                  practices to improve access and deliver care simply.
                </div>
                <div className='about__axs--email'>
                  <a href='mailto:info@axshealthapp.com'>
                    info@axshealthapp.com
                  </a>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <Col xs={{ span: 12 }} sm={{ span: 'auto' }}>
          <div className='about__dot--container'>
            <div className='about__dot--small' />
            <div className='about__dot' />
          </div>
        </Col>
      </Row>
    </Fragment>
  )
}
