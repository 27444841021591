import React, { Fragment, useEffect } from 'react'
import WelcomeButtons from './Components/WelcomeButtons'
import ForPatientsDetail from './Components/ForPatientsDetail'

import FormsImg from '../../_assets/Shared/MacbookForms@3x.png'
import EventsImg from '../../_assets/Shared/MacbookEvents@3x.png'
import background2 from '../../_assets/Home/2.png'
import background3 from '../../_assets/Home/3.png'
import MessagesImg from '../../_assets/Shared/MacbookMessages@3x.png'
import TelehealthImg from '../../_assets/Shared/MacbookTelehealth@3x.png'
import DigitalRegistration3x from '../../_assets/Shared/DigitalRegistration@3x.png'

import { Link } from 'react-router-dom'
import { Button, Col, Image, Row } from 'react-bootstrap'

import {
  scrollInPatientDetails,
  scrollInForProviderDetails
} from '../../_helpers/handleAnimation'

export default function Home() {
  useEffect(() => {
    window.scroll(0, 0)
    window.addEventListener('scroll', scrollInPatientDetails)
    window.addEventListener('scroll', scrollInForProviderDetails)

    return () => {
      window.removeEventListener('scroll', scrollInPatientDetails)
      window.removeEventListener('scroll', scrollInForProviderDetails)
    }
  }, [])

  return (
    <Fragment>
      {/* Welcome */}
      <div className='welcome__slide'>
        <div className='bubble__background mx-auto h-100'>
          <Row className='container-lg m-auto h-100 py-3'>
            <Col sm={9} md={4} lg={5} className='welcome__info my-auto'>
              <div className='welcome__to'>WELCOME TO</div>
              <div className='welcome__axs'>AxS Health</div>
              <div className='welcome__details'>
                Patients and Providers deserve a better experience in
                healthcare. AxS Health is dedicated to achieving this by
                simplifying access and streamlining healthcare delivery.
              </div>
            </Col>
            <Col sm={12} md={8} lg={7} className='my-auto'>
              <WelcomeButtons />
            </Col>
          </Row>
        </div>
      </div>

      {/* For Providers */}
      <div className='for__providers'>
        <div className='container text-center mx-auto'>
          <Row className='for__providers--title'>
            <Col>
              <p>FOR HEALTHCARE PROVIDERS</p>
            </Col>
          </Row>

          <Row>
            <Col>
              <h1 className='font-weight-normal'>
                We have the right <span className='text-primary'>solution</span>{' '}
                for you
              </h1>
            </Col>
          </Row>

          <div id='for__providers--documents-section'>
            <Row className='for__providers--details my-5 py-5'>
              <Col
                xs={{ span: 12, order: 'last' }}
                lg={{ span: 5, order: 'first' }}
                className='text-left'
              >
                <div className='text-left'>
                  <Row>
                    <Col className='solution__info--title text-center text-md-left'>
                      <h1>Digital Documents</h1>
                    </Col>
                  </Row>

                  <hr />

                  <div className='solution__info--description mb-4'>
                    Send and receive your organization's forms, surveys,
                    instructions, care plans, assessments and other documents
                    for completion, patient acknowledgement or consent.
                    <br />
                    <ul className='solution__list'>
                      <li className='my-2'>Digital Patient Registration</li>
                      <li className='my-2'>Patient Satisfaction Surveys</li>
                      <li className='my-2'>Outcomes Survey</li>
                      <li className='my-2'>Patient Instructions</li>
                    </ul>
                  </div>

                  <br />

                  <Button
                    as={Link}
                    to='/solutions/documents'
                    className='learn__more'
                  >
                    <small className='font-weight-bold px-4'>Learn More</small>
                  </Button>
                </div>
              </Col>
              <Col
                xs={{ span: 12, order: 'first' }}
                lg={{ span: 7, order: 'last' }}
              >
                <Image fluid src={FormsImg} />
              </Col>
            </Row>
          </div>
        </div>

        <div
          id='for__providers--telehealth-section'
          className='for__providers--bg-blue '
        >
          <div className='container text-center mx-auto'>
            <Row className='for__providers--details my-5 py-5'>
              <Col xs={{ span: 12 }} lg={{ span: 7 }}>
                <Image fluid src={TelehealthImg} />
              </Col>
              <Col xs={{ span: 12 }} lg={{ span: 5 }} className='text-left'>
                <Row>
                  <Col className='solution__info--title text-center text-md-left'>
                    <h1>Telehealth</h1>
                  </Col>
                </Row>

                <hr />

                <div className='solution__info--description mb-4'>
                  Patients and Providers benefit from secure telehealth. Use
                  real-time video or text based telehealth for physician visits,
                  nurse call backs, pre-op/post-op assessments, case management,
                  and therapy visits.
                  <br />
                  <ul className='solution__list'>
                    <li className='my-2'>
                      Pre-visit Assessment Patient Question
                    </li>
                    <li className='my-2'>Treatment Plan for Patient</li>
                    <li className='my-2'>Pre-operative Patient Assessments</li>
                    <li className='my-2'>Post-operative Status Check</li>
                  </ul>
                </div>

                <br />

                <Button
                  as={Link}
                  to='/solutions/telehealth'
                  className='learn__more'
                >
                  <small className='font-weight-bold px-4'>Learn More</small>
                </Button>
              </Col>
            </Row>
          </div>
        </div>

        <div id='for__providers--messages-section'>
          <div className='container text-center mx-auto'>
            <Row className='for__providers--details my-5 py-5'>
              <Col
                xs={{ span: 12, order: 'last' }}
                lg={{ span: 5, order: 'first' }}
                className='text-left'
              >
                <div className='text-left'>
                  <Row>
                    <Col className='solution__info--title text-center text-md-left'>
                      <h1>Messages</h1>
                    </Col>
                  </Row>

                  <hr />

                  <div className='solution__info--description mb-4'>
                    Accessing a healthcare provider by phone is often a
                    dissatisfying process for patients. Messaging provides
                    secure communication with patients that reduces the need for
                    telephone calls.
                    <br />
                    <ul className='solution__list'>
                      <li className='my-2'>
                        Send and Receive Documents and Images
                      </li>
                      <li className='my-2'>Treatment Care Plans</li>
                      <li className='my-2'>Workers Compensation Reports</li>
                    </ul>
                  </div>

                  <br />

                  <Button
                    as={Link}
                    to='/solutions/messages'
                    className='learn__more'
                  >
                    <small className='font-weight-bold px-4'>Learn More</small>
                  </Button>
                </div>
              </Col>
              <Col
                xs={{ span: 12, order: 'first' }}
                lg={{ span: 7, order: 'last' }}
              >
                <Image fluid src={MessagesImg} />
              </Col>
            </Row>
          </div>
        </div>

        <div
          id='for__providers--events-section'
          className='for__providers--bg-blue'
        >
          <div className='container text-center mx-auto'>
            <Row className='for__providers--details my-5 py-5'>
              <Col xs={{ span: 12 }} lg={{ span: 7 }}>
                <Image fluid src={EventsImg} />
              </Col>
              <Col xs={{ span: 12 }} lg={{ span: 5 }} className='text-left'>
                <Row>
                  <Col className='solution__info--title text-center text-md-left'>
                    <h1>Care Events</h1>
                  </Col>
                </Row>

                <hr />

                <div className='solution__info--description mb-4'>
                  With Events, your organization can schedule all communication
                  (forms, telehealth visits, instructions, assessments) related
                  to a care event such as surgery date.
                  <br />
                </div>

                <br />

                <Button
                  as={Link}
                  to='/solutions/events'
                  className='learn__more'
                >
                  <small className='font-weight-bold px-4'>Learn More</small>
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </div>

      {/* For Patients */}
      <div id='for__patient__section' className='for__patient'>
        <div className='container text-center mx-autolg-'>
          <Row className='for__patient--title'>
            <Col>
              <p>FOR PATIENTS</p>
            </Col>
          </Row>
          <Row className='for__patient--secured'>
            <Col>
              Simple and <span className='for__patient--secure'>Secure</span>
            </Col>
          </Row>
          <Row>
            <Col className='p-0' sm={12} lg={2}>
              <Row>
                <Col className='p-0'>
                  <ForPatientsDetail
                    dot='simple'
                    title='Simple'
                    align='for__patient__dot--right'
                    detail='Mobile friendly for patients and simple navigation for providers with health profile, inquiry responses, 
                                    simple distribution of information and chat/video telemedicine functions all on a single screen.'
                  />
                </Col>
              </Row>
              <Row>
                <Col className='for__patient--padding px-0'>
                  <ForPatientsDetail
                    dot='secured'
                    title='Secure'
                    align='for__patient__dot--right'
                    detail='All transfer of data is secured via SSL/TLS encryption. Your data is only accessible by your healthcare provider.'
                  />
                </Col>
              </Row>
            </Col>
            <Col className='p-0 d-none d-lg-block' lg={8}>
              <div className='for__patient--dashboard'>
                <img
                  alt='registration'
                  className='for__patient__iphone'
                  src={DigitalRegistration3x}
                />
                <img
                  alt='background2'
                  className='for__patient__iphone--background2'
                  src={background2}
                />
                <img
                  alt='background3'
                  className='for__patient__iphone--background3'
                  src={background3}
                />
              </div>
            </Col>
            <Col className='p-0' sm={12} lg={2}>
              <Row>
                <Col className='p-0'>
                  <ForPatientsDetail
                    dot='message'
                    title='Message your Provider'
                    align='for__patient__dot--left'
                    detail='Once you have developed a professional relationship with your healthcare provider you can avoid the often 
                                    frustrating experience of telephone calls and utilize the AxS Health Messaging function to directly contact your healthcare team.'
                  />
                </Col>
              </Row>
              <Row>
                <Col className='for__patient--padding px-0'>
                  <ForPatientsDetail
                    dot='platform'
                    title='Desktop and Mobile'
                    align='for__patient__dot--left'
                    detail='The AxS health App provides the convenience of both desktop and mobile access.'
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </Fragment>
  )
}
