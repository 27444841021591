import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { scrollToSection } from '../../../_helpers/handleAnimation'

export default function WelcomeButtons() {
  const yOffset = window.innerWidth < 992 ? -50 : -150

  return (
    <div className='welcome__buttons'>
      <Row className='mb-5'>
        <Col className='text-center'>
          <div
            className='welcome__button'
            onClick={() =>
              scrollToSection('for__providers--documents', yOffset)
            }
          >
            Digital Documents
          </div>
        </Col>
        <Col className='text-center'>
          <div
            className='welcome__button'
            onClick={() =>
              scrollToSection('for__providers--telehealth', yOffset)
            }
          >
            Telehealth
          </div>
        </Col>
      </Row>
      <Row>
        <Col className='text-center'>
          <div
            className='welcome__button'
            onClick={() => scrollToSection('for__providers--messages', yOffset)}
          >
            Messages
          </div>
        </Col>
        <Col className='text-center'>
          <div
            className='welcome__button'
            onClick={() => scrollToSection('for__providers--events', yOffset)}
          >
            Care Events
          </div>
        </Col>
      </Row>
    </div>
  )
}
