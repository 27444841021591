import React, { useState, useEffect } from 'react'
import logo from '../../_assets/Shared/logo@3x.png'

import { Link } from 'react-router-dom'
import { history } from '../../_helpers/history'
import { Col, Nav, Row, Image, Navbar, Container } from 'react-bootstrap'

import './NavBar.css'

export default function NavBar() {
  const [active, setActive] = useState('')
  const [toggleMenu, setToggleMenu] = useState(false)

  useEffect(() => {
    setActive(history.location.pathname)
  }, [history.location.pathname])

  return (
    <>
      <Navbar className='d-none d-lg-block'>
        <Row className='axs__navbar m-sm-0 container-md mx-md-auto'>
          <Col xs={4} className='d-flex align-content-center flex-wrap'>
            <Nav>
              <Navbar.Brand as={Link} to='/'>
                <Image src={logo} className='axs__navbar--logo' />
              </Navbar.Brand>
            </Nav>
          </Col>
          <Col
            xs={8}
            className='d-flex align-content-center flex-wrap font-weight-bold'
          >
            <Nav
              className='w-100 flex-wrap d-flex'
              style={{ justifyContent: 'space-evenly' }}
            >
              <Nav.Link
                as={Link}
                to='/home'
                className='nav-link'
                active={active.indexOf('/home') === 0}
              >
                {' '}
                Home{' '}
              </Nav.Link>

              <Nav.Link
                as={Link}
                to='/solutions'
                className='nav-link'
                active={active.indexOf('/solutions') === 0}
              >
                {' '}
                Solutions{' '}
              </Nav.Link>

              {/* <Nav.Link
                as={Link}
                to='/medical-groups'
                className='nav-link'
                active={active.indexOf('/medical-groups') === 0}
              >
                {' '}
                Medical Groups{' '}
              </Nav.Link>

              <Nav.Link
                as={Link}
                to='/surgery-centers'
                className='nav-link'
                active={active.indexOf('/surgery-centers') === 0}
              >
                {' '}
                Surgery Centers{' '}
              </Nav.Link>

              <Nav.Link
                as={Link}
                to='/hospitals'
                className='nav-link'
                active={active.indexOf('/hospitals') === 0}
              >
                {' '}
                Hospitals{' '}
              </Nav.Link> */}

              <Nav.Link
                as={Link}
                to='/about'
                className='nav-link'
                active={active.indexOf('/about') === 0}
              >
                {' '}
                About{' '}
              </Nav.Link>
            </Nav>
          </Col>
        </Row>
      </Navbar>

      <Navbar
        bg='light'
        expand='lg'
        collapseOnSelect
        className='d-block d-lg-none py-4'
      >
        <Container fluid>
          <Navbar.Brand as={Link} to='/' className='navbar-brand'>
            <Image src={logo} className='axs__navbar--logo' />
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls='basic-navbar-nav'
            onClick={() => setToggleMenu(!toggleMenu)}
          />
          <Navbar.Collapse
            id='basic-navbar-nav'
            in={toggleMenu}
            className='text-center'
          >
            <Nav className='my-auto'>
              <Nav.Link
                as={Link}
                to='/home'
                className='nav-link'
                active={active.indexOf('/home') === 0}
                onClick={() => setToggleMenu(false)}
              >
                {' '}
                Home{' '}
              </Nav.Link>

              <Nav.Link
                as={Link}
                to='/solutions'
                className='nav-link'
                active={active.indexOf('/solutions') === 0}
                onClick={() => setToggleMenu(false)}
              >
                {' '}
                Solutions{' '}
              </Nav.Link>

              {/* <Nav.Link
                as={Link}
                to='/medical-groups'
                className='nav-link'
                active={active.indexOf('/medical-groups') === 0}
                onClick={() => setToggleMenu(false)}
              >
                {' '}
                Medical Groups{' '}
              </Nav.Link>

              <Nav.Link
                as={Link}
                to='/surgery-centers'
                className='nav-link'
                active={active.indexOf('/surgery-centers') === 0}
                onClick={() => setToggleMenu(false)}
              >
                {' '}
                Surgery Centers{' '}
              </Nav.Link>

              <Nav.Link
                as={Link}
                to='/hospitals'
                className='nav-link'
                active={active.indexOf('/hospitals') === 0}
                onClick={() => setToggleMenu(false)}
              >
                {' '}
                Hospitals{' '}
              </Nav.Link> */}

              <Nav.Link
                as={Link}
                to='/about'
                className='nav-link'
                active={active.indexOf('/about') === 0}
                onClick={() => setToggleMenu(false)}
              >
                {' '}
                About{' '}
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  )
}
