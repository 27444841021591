import React, { useEffect } from 'react'
import { Row, Col, Container } from 'react-bootstrap'

export default function Terms() {
  useEffect(() => {
    window.scroll(0, 0)
  }, [])

  return (
    <Container>
      <Row>
        <Col>
          <h1>Terms of Use</h1>
          <div id='termsofuse' className='terms-of-use-content'>
            <p>Effective Date: August 1, 2017</p>
            <p>
              The following terms and conditions constitute an agreement between
              you (" <strong>you</strong>") and AxS Healthcare, Inc. ("{' '}
              <strong>AxS</strong>," " <strong>we</strong>," "{' '}
              <strong>us</strong>" or " <strong>our</strong>"), the operator of
              axshealthapp.com (the " <strong>Site</strong>") and related
              websites, applications, mobile applications, and services provided
              thereunder (collectively, the "Services").{' '}
            </p>
            <p>These terms and conditions, together with the:</p>
            <p>
              <a href='#Privacy-Policy'>Privacy Policy</a>
            </p>
            <p>
              <a href='#Acceptable-Use'>Acceptable Use Policy</a>
            </p>
            <p>
              <a href='#Sale-And-Addl-Terms'>Sale and Additional Terms</a>
            </p>
            <p>
              <a href='#Informed-Consent'>
                Informed Consent of Telemedicine Services
              </a>
            </p>
            <p>
              and each other document referenced herein, each of which is
              incorporated herein, collectively constitute the "Term of Use." BY
              VISITING, USING, REGISTERING, OR OTHERWISE ACCESSING THE SITE AND
              THE SERVICES, YOU AGREE TO BE BOUND BY THE TERMS OF USE; IF YOU DO
              NOT AGREE, DO NOT USE THE SITE OR THE SERVICES.
            </p>
            <ol>
              <li>ABOUT THE SITE AND THE SERVICES</li>
            </ol>
            <p>
              AxS is a digital health platform dedicated to streamlining your
              access to common healthcare needs. Our Services facilitate the
              telemedicine consultations between you and a licensed healthcare
              provider, including physicians, nurse practitioners and physician
              assistants (the "Healthcare Provider") for the treatment of
              certain medical conditions.
            </p>
            <p>What we do:</p>
            <ul>
              <li>
                We do offer a technology platform for you to connect with
                certain Healthcare Providers to schedule appointments and pay
                for such healthcare services.
              </li>
              <li>
                We do offer a secure environment to communicate your personal
                information to certain Healthcare Providers as appropriately
                authorized by you.
              </li>
              <li>
                We do offer access to telemedicine services through which you
                answer questions regarding your condition and send relevant
                photos and a Healthcare Provider will respond with a treatment
                plan and a prescription (as appropriate).
              </li>
            </ul>
            <p>What we don't do:</p>
            <ul>
              <li>
                We don't provide medical advice (we leave that to the Healthcare
                Providers).
              </li>
              <li>
                We don't control or interfere with the Healthcare Provider's
                provision of medical care to you.
              </li>
            </ul>
            <ol>
              <li>
                THE DIFFERENT TYPES OF CONTENT PROVIDED ON THE SITE AND THROUGH
                THE SERVICES
              </li>
            </ol>
            <p>
              We communicate or may communicate through the Site and the
              Services by using text, graphics, images, videos, photographs,
              audio, interfaces, content, sounds, artwork, computer code, and
              other information or materials posted or provided on, by, or
              through the Site and the Services (collectively, the "Content").
              Some of the Content is provided by us (the "AxS Content"), and
              some of the Content is provided by others, including Content
              provided by Healthcare Providers. We have no right to control,
              interfere with, edit, or otherwise influence the Content that is
              provided by Healthcare Providers.
            </p>
            <ol>
              <li>WE DO NOT PROVIDE MEDICAL ADVICE</li>
            </ol>
            <p>
              We do not provide medical advice (we leave that to the Healthcare
              Providers). The AxS Content, including general information
              accessible on the Site and communications from AxS or its
              employees or agents related to your use and/or payment for the
              Services, and all other Content you receive from the Site or the
              Services (other than from a Healthcare Provider) is provided for
              informational, scheduling and payment purposes only. None of the
              AxS Content should be considered medical advice, even if it is of
              a health or medical nature.
            </p>
            <p>
              NOTHING STATED OR POSTED ON THE SITE OR AVAILABLE THROUGH THE
              SERVICES IS INTENDED TO BE, AND MUST NOT BE TAKEN TO BE, THE
              PRACTICE OF MEDICINE, DENTISTRY, NURSING, OR OTHER PROFESSIONAL
              HEALTH CARE ADVICE, OR THE PROVISION OF MEDICAL CARE BY AXS.
            </p>
            <p>
              Only the Content received directly from a Healthcare Provider
              should be considered medical advice. You may receive Content from
              a Healthcare Provider through use of the Site and the Services,
              emails, or text messages. When you are in personal contact with
              your Healthcare Provider (i.e. text and/or email communications,
              you agree to personally notify your Healthcare Provider of any
              information you believe to be important to your diagnosis or
              treatment.
            </p>
            <p>
              The Site is appropriately used as a supplement to, and not in lieu
              of, other forms of health care. You agree not to delay or avoid
              getting medical care from other healthcare providers because of
              any information on this Site or provided to you or obtained by you
              through the Services. You should always talk to your health care
              professionals for diagnosis and treatment, including information
              regarding which drugs or treatment may be appropriate for you. Any
              medical advice provided to you by Healthcare Providers through the
              Services is based on your personal health data as provided by you
              and the local standards of care for your presenting symptoms based
              on your use of the Services.
            </p>
            <p>
              The Site and the Services are not for emergency use. If you have
              OR THINK YOU HAVE a medical emergency, please contact 9-1-1
              immediately or seek emergency medical help.
            </p>
            <ol>
              <li>NO DOCTOR-PATIENT RELATIONSHIP</li>
            </ol>
            <p>
              NO LICENSED MEDICAL PROFESSIONAL/PATIENT RELATIONSHIP IS CREATED
              BETWEEN YOU AND AXS BY USING THE CONTENT, WHETHER SUCH CONTENT IS
              PROVIDED BY OR THROUGH THE USE OF THE SITE, THE SERVICES OR
              THROUGH ANY OTHER COMMUNICATIONS FROM AXS, INCLUDING, BUT NOT
              LIMITED TO, EMAILS OR TEXT MESSAGES FROM AXS, LINKS TO OTHER SITES
              FROM OUR SITE, OUR "ASK A DOCTOR" FEATURE, OR ANY ASSISTANCE WE
              MAY PROVIDE TO HELP YOU FIND AN APPROPRIATE HEALTHCARE PROVIDER IN
              ANY FIELD.
            </p>
            <ol>
              <li>CHOOSING A HEALTHCARE PROVIDER</li>
            </ol>
            <p>
              In connection with using the Site and the Services to locate and
              schedule appointments with Healthcare Providers, you understand
              that YOU ARE ULTIMATELY RESPONSIBLE FOR CHOOSING YOUR OWN
              HEALTHCARE PROVIDER. You agree that in exercising your
              responsibility to choose a Healthcare Provider, you are strongly
              advised to perform your own investigation of the Healthcare
              Provider prior to requesting an appointment, such as confirming
              with licensing or certification authorities to verify listed
              credentials, education, and other information that may be relevant
              for such Healthcare Provider's specialty. Your schedule, the
              schedules of the Healthcare Providers, and other circumstances
              beyond our control may affect your ability to choose a Healthcare
              Provider and schedule an appointment. We have no control over, and
              cannot guarantee the availability of, any Healthcare Provider at
              any particular time.
            </p>
            <p>
              We use reasonable efforts to ensure that Healthcare Providers only
              participate in the Services if they hold all active licenses
              required by law to practice the specialties of the services
              offered by them, and are not excluded from participation in the
              Medicare and Medicaid programs. AxS may exclude Healthcare
              Providers who, in our sole discretion, have engaged in
              inappropriate or unprofessional conduct.
            </p>
            <p>
              We will provide you with search results of Healthcare Providers
              who may be able to provide the healthcare services you seek based
              on information that you provide to us (such as insurance
              information, geographical location, and healthcare specialty). The
              search results may also be based on other criteria (including,
              availability, past selections and/or ratings by you or by other
              registered user, and past experiences of other registered users
              with Healthcare Providers. Ratings are based only on users'
              reviews.
            </p>
            <p>
              We DO NOT promote or feature Healthcare Providers on our list of
              Healthcare Providers because they pay us to do so. We may,
              however, receive additional fees from healthcare-related
              businesses that advertise on our Site and may provide special
              offers or promotions to AxS users from time to time will feature
              those special offers or promotions on the Site and through the
              Services ("Sponsored Offers").
            </p>
            <ol>
              <li>REQUIRED AGE TO USE THE SITE AND THE SERVICES</li>
            </ol>
            <p>
              You must be 18 years of age or over, or the legal age to form a
              binding contract in your jurisdiction if that age is greater than
              18 years of age, to register with us or use the Site and the
              Services. If you are between the ages of 13 and 18 or the
              applicable legal age in your jurisdiction, you can use the Site or
              Services only under the supervision of your parent or guardian who
              has agreed to the Terms of Use. If you are under the age of 13,
              you may not use the Site or Services. If you are the parent or
              legal guardian of a child between the ages of 13 and 18, you may
              use the Site or Services on behalf of such minor child.
            </p>
            <p>
              By using the Site or Services on behalf of a minor child, you
              represent and warrant that you are the parent or legal guardian of
              such child and that all references in these Terms of Use to "you"
              shall refer to such child or such other individual for whom you
              have authorization to enter into these Terms of Use on their
              behalf, and you in your capacity as the parent or legal guardian
              of such child or as the authorized party of such individual. If
              you do not qualify under these terms, do not use the Site or
              Services.
            </p>
            <ol>
              <li>ACCESSING THE SITE AND SERVICES; ACCOUNT SECURITY</li>
            </ol>
            <p>
              Portions of the Site and the Services are viewable without
              registering as a user with us. To register with us, you will be
              asked to provide certain personal information. It is a condition
              of your use of the Site that all the information you provide on
              the Site is correct, current and complete. You agree that all
              information you provide to register with the Site or otherwise,
              including but not limited to the use of any interactive features
              on the Site, is governed by our{' '}
              <a href='#Privacy-Policy'>Privacy Policy</a>, and you consent to
              all actions we take with respect to your information consistent
              with our Privacy Policy.{' '}
            </p>
            <p>
              As a visitor or registered user, you have a limited, personal and
              noncommercial, nontransferable right to access and use the Site
              and the Services, subject to the Terms of Use. You are responsible
              for making all arrangements necessary for you to have access to
              the Site and ensuring that all persons who access the Site through
              your internet connection are aware of these Terms of Use and
              comply with them. Your user name, password, and any other security
              credentials to access the Site and the Services are confidential,
              and you agree not to disclose it to any other person or entity.
              You agree that you must promptly inform us if your user name,
              password, and any other security credentials are lost or stolen.
              We reserve the right to disable any user name, password, or other
              security credential and deny use of the Site or Services at any
              time for any or no reason.
            </p>
            <p>
              We reserve the right to withdraw or amend the Site, and any
              service or material we provide on the Site, in our sole discretion
              without notice. From time to time, we may restrict access to some
              parts of the Site, or the entire Site, to registered users.
            </p>
            <ol>
              <li>INTELLECTUAL PROPERTY RIGHTS</li>
            </ol>
            <p>
              The Site, the Services, and the Content, including all
              functionality, design, arrangement, structure, expression, and
              "look and feel" of the Content is owned by AxS, its licensors, or
              other third parties and is protected by United States and
              international copyright, trademark, patent, trade secret and other
              intellectual property or proprietary rights laws.
            </p>
            <p>
              You must not access or use any part of the Site or the Services
              for any commercial purposes. All rights not expressly granted to
              you by AxS in the Terms of Use are reserved by AxS. Any use of the
              Site not expressly permitted by these Terms of Use is a breach of
              these Terms of Use and may violate copyright, trademark and other
              laws.
            </p>
            <p>
              You can download, print, or store Content from the Site for your
              personal, non-commercial use. You cannot reproduce, distribute,
              modify, create derivative works of, publicly display, publicly
              perform, republish, download, store or transmit any of the Content
              for a commercial use or for any use that isn't solely related to
              your personal use of the Site and the Services. You cannot delete
              or alter any copyright, trademark or other proprietary rights
              notices from copies of materials from this site.
            </p>
            <p>
              The AxS name and trademarks, including words and logo, and all
              related names, logos, product and service names, designs and
              slogans are trademarks of AxS or its affiliates or licensors. You
              must not use such marks without our prior written permission. All
              other names, logos, product and services names, designs and
              slogans on the Site are the trademarks of their respective owners.
            </p>
            <ol>
              <li>ACCEPTABLE USE POLICY</li>
            </ol>
            <p>
              All uses of the Site and the Services, including all Content
              generated by you or other registered users of the Site and the
              Services are subject to the terms and conditions of our{' '}
              <a href='#Acceptable-Use'>Acceptable Use Policy</a>, in effect
              from time to time as set forth therein.{' '}
            </p>
            <ol>
              <li>YOUR PERSONAL INFORMATION AND OUR PRIVACY POLICY</li>
            </ol>
            <p>
              All information we collect on the Site is subject to our{' '}
              <a href='#Privacy-Policy'>Privacy Policy</a>, in effect from time
              to time as set forth therein. By using the Site, you consent to
              all actions taken by us with respect to your information in
              compliance with the Privacy Policy.{' '}
            </p>
            <ol>
              <li>TERMS OF SALE AND ADDITIONAL TERMS</li>
            </ol>
            <p>
              All purchases through our Site or other transactions for the sale
              of services through the Site or as a result of visits made by you
              are governed by our{' '}
              <a href='#Sale-and-Addl-Terms'>Sale and Additional Terms</a>, in
              effect from time to time as set forth therein. The Sale and
              Additional Terms also contain additional terms and conditions
              related to your use of the Site and the Services.{' '}
            </p>
            <ol>
              <li>VIOLATION OF TERMS OF USE; TERMINATION</li>
            </ol>
            <p>
              In addition to any other rights we have reserved in the Terms of
              Use, we reserve the right to terminate your access to the Site and
              use of the Services if you violate any terms of the Terms of Use,
              including the Privacy Policy, the Acceptable Use Policy and the
              Sale and Additional Terms. In the event that we terminate your
              access to the Site and deny you access to or use of the Services
              (whether through this section or elsewhere as provided in the
              Terms of Use), the Terms of Use shall survive termination of your
              access to the Site and the Services and your obligations set forth
              herein shall continue in full force and effect.
            </p>
            <ol>
              <li>UPDATES TO THE SITE</li>
            </ol>
            <p>
              We may update, modify, or remove the Content on the Site from time
              to time, but its Content is not necessarily complete or
              up-to-date. Any of the Content on the Site may be out of date at
              any given time, and we are under no obligation to update such
              Content.
            </p>
            <ol>
              <li>LINKS FROM THE SITE</li>
            </ol>
            <p>
              If the Site contains links to other sites and resources provided
              by third parties, these links are provided for your convenience
              only. This includes links contained in advertisements, including
              banner advertisements, sponsored links and links for Sponsored
              Offers. We have no control over the contents of those sites or
              resources, and accept no responsibility for them or for any loss
              or damage that may arise from your use of them. If you decide to
              access any of the third party websites linked to this Site, you do
              so entirely at your own risk and subject to the terms and
              conditions of use for such websites.
            </p>
            <ol>
              <li>GEOGRAPHIC RESTRICTIONS</li>
            </ol>
            <p>
              The Services are not available in all states and are available
              only in states in which we have established relationships with
              Healthcare Providers. In addition, use of the Services is not
              available for users residing in states where offering, accessing
              or using our Services would be illegal or unlawful.
            </p>
            <ol>
              <li>DISCLAIMER OF WARRANTIES</li>
            </ol>
            <p>
              The Site, the Services, and all Content is available "as is" and
              "as available" and without any warranties of any kind. YOUR USE OF
              THE SITE AND THE SERVICES IS SOLELY AT YOUR OWN RISK.
            </p>
            <p>Without limiting the generality of the foregoing:</p>
            <ul>
              <li>
                WE MAKE NO GUARANTEES, REPRESENTATIONS OR WARRANTIES, WHETHER
                EXPRESSED OR IMPLIED, OF MERCHANTABILITY, NON-INFRINGEMENT OR
                FITNESS FOR PARTICULAR PURPOSE.
              </li>
              <li>
                WE MAKE NO GUARANTEES, REPRESENTATIONS OR WARRANTIES, WHETHER
                EXPRESSED OR IMPLIED, WITH RESPECT TO PROFESSIONAL
                QUALIFICATIONS OR CREDENTIALS OF ANY HEALTHCARE PROVIDERS,
                EXPERTISE OR QUALITY OF WORK OF ANY HEALTHCARE PROVIDER, PRICE
                OR COST INFORMATION, OR YOUR INSURANCE COVERAGE OR BENEFIT
                INFORMATION.
              </li>
              <li>
                WE MAKE NO GUARANTEES, REPRESENTATIONS OR WARRANTIES, WHETHER
                EXPRESSED OR IMPLIED, WITH RESPECT to completeness, accuracy,
                reliability, or availability of the Site, the Services, of A
                HEALTHCARE PROVIDER or any Content.
              </li>
              <li>
                IN NO EVENT SHALL WE BE LIABLE TO YOU OR ANYONE ELSE FOR ANY
                DECISION MADE OR ACTION TAKEN BY YOU IN RELIANCE ON ANY CONTENT
                ON THE SITE.
              </li>
              <li>
                WE DO NOT IN ANY WAY ENDORSE OR RECOMMEND ANY INDIVIDUAL
                HEALTHCARE PROVIDER LISTED OR ACCESSIBLE THROUGH THE SERVICES;
                ANY specific tests, procedures, opinions, or other information
                that may appear through the Services; OR that any particular
                drug or treatment is safe, appropriate, or effective for you.
              </li>
              <li>
                We do not guaranty that any Healthcare Provider has read,
                received, reviewed or understood any personal health information
                which may be provided by you or pertain to you.
              </li>
              <li>
                WE MAKE NO GUARANTEES, REPRESENTATIONS OR WARRANTIES, WHETHER
                EXPRESSED OR IMPLIED, THAT THE SITE OR THE SERVER THAT MAKES IT
                AND THE SERVICES AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL
                COMPONENTS OR THAT THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED
                THROUGH THE WEBSITE WILL OTHERWISE MEET YOUR NEEDS OR
                EXPECTATIONS.
              </li>
            </ul>
            <p>
              You understand that we cannot and do not guarantee or warrant that
              files available for downloading from the internet or the Site will
              be free of viruses or other destructive code. You are responsible
              for implementing sufficient procedures and checkpoints to satisfy
              your particular requirements for anti-virus protection and
              accuracy of data input and output, and for maintaining a means
              external to our Site for any reconstruction of any lost data. WE
              WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED
              DENIAL-OF-SERVICE ATTACK, VIRUSES OR OTHER TECHNOLOGICALLY HARMFUL
              MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER
              PROGRAMS, DATA OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF
              THE SITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SITE OR TO
              YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY WEBSITE
              LINKED TO IT.
            </p>
            <p>
              The foregoing does not affect any warranties that cannot be
              excluded or limited under applicable law.
            </p>
            <ol>
              <li>LIMITATION ON LIABILITY</li>
            </ol>
            <p>
              TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT
              WILL AXS, ITS AFFILIATES OR LICENSORS, SERVICE PROVIDERS,
              EMPLOYEES, AGENTS, OFFICERS OR DIRECTORS BE LIABLE FOR DAMAGES OF
              ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION
              WITH YOUR USE, OR INABILITY TO USE, THE SITE OR THE SERVICES,
              INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL
              OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL
              INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE,
              LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF
              USE, LOSS OF GOODWILL, LOSS OF DATA AND WHETHER CAUSED BY TORT
              (INCLUDING NEGLIGENCE), BREACH OF CONTRACT OR OTHERWISE, EVEN IF
              FORESEEABLE.
            </p>
            <p>
              Without limiting the foregoing, we will not be liable for
              cancelled or otherwise unfulfilled appointments, or any injury
              resulting therefrom, or for any other injury resulting or arising
              from or related to the use of the Site or Services whatsoever.
            </p>
            <p>
              The foregoing does not affect any liability that cannot be
              excluded or limited under applicable law.
            </p>
            <ol>
              <li>INDEMNIFICATION AND RELEASE</li>
            </ol>
            <p>
              You agree to indemnify and hold harmless AxS, its affiliates,
              subsidiaries, licensors, service providers, officers, directors,
              employees, contractors, agents, successors, and assigns from and
              against any claims, liabilities, damages, judgments, awards,
              losses, costs, penalties, expenses or fees (including reasonable
              attorneys' and consultants' fees) arising out of or relating to
              your violation of the Terms of Use, your use of the Site, or your
              use of the Services.
            </p>
            <ol>
              <li>RESOLUTION OF DISPUTES</li>
            </ol>
            <p>
              In the unlikely event that AxS has not been able to resolve a
              dispute it has with you after attempting to do so informally (in
              its sole discretion), you agree to resolve any claim, dispute, or
              controversy (excluding any claims we might have for injunctive or
              other equitable relief) arising out of or relating to the Terms of
              Use, the Site, or the Services (collectively, "Claims") by binding
              arbitration by the American Arbitration Association (" AAA" ) in
              San Bernardino County, California under the commercial rules then
              in effect for the AAA, except as provided herein. The award
              rendered by the arbitrator shall include costs of arbitration,
              reasonable attorneys' fees and reasonable costs for expert and
              other witnesses, and any judgment on the award rendered by the
              arbitrator may be entered in any court of competent jurisdiction.
              Nothing in this section shall be deemed as preventing AxS from
              seeking injunctive or other equitable relief from the courts as
              necessary to protect any of AxS' proprietary interests.
            </p>
            <p>
              ALL CLAIMS BY A VISITOR OR USER MUST BE BROUGHT BY THE VISITOR OR
              USER IN HIS OR HER INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR
              CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING
              (INCLUDING CLASS ACTION ARBITRATION). YOU AGREE THAT, BY ENTERING
              INTO THESE TERMS, YOU AND AXS ARE EACH WAIVING THE RIGHT TO A
              TRIAL BY JURY OR TO PARTICIPATE IN A CLASS ACTION.
            </p>
            <ol>
              <li>LIMITATION ON TIME TO FILE CLAIMS</li>
            </ol>
            <p>
              ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR
              RELATING TO THE TERMS OF USE, THE SITE, OR THE SERVICES MUST BE
              COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES,
              OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.
            </p>
            <ol>
              <li>OTHER GENERAL TERMS</li>
            </ol>
            <p>
              Governing Law. The Terms of Use and any disputes arising
              therefrom, related thereto, or arising from or relating to the
              Site or the Services shall be governed by laws of the State of
              California without regards to its conflicts of law principles
              which might require the application of the laws of any other
              jurisdiction.
            </p>
            <p>
              Waiver and Severability. In the event of the invalidity of any
              provision of the Terms of Use, such provision shall be deemed
              stricken from the Terms of Use, which shall continue in full force
              and effect as if the offending provision were never a part of the
              Terms of Use.
            </p>
            <p>
              Paragraph Titles and Interpretation. The titles, headings and
              other captions in the Terms of Use are for convenience and
              reference only and shall not be used in interpreting, construing
              or enforcing any of the provisions of the Terms of Use. Unless the
              context requires otherwise, the term "including" as used in the
              Terms of Use shall mean "including, but not limited to."
            </p>
            <p>
              Assignment. The Terms of Use may be assigned by AxS at any time,
              including to any parent, affiliate, or subsidiary, or as part of a
              sale of all or substantially all of the assets to, merger with, or
              other transfer of AxS to another entity. You may not assign the
              Terms of Use whatsoever and any attempted or purported assignment
              in violation of this section shall be null and void and without
              any effect.
            </p>
            <p>
              Entire Agreement; Modification. The Terms of Use, in effect from
              time to time as set forth herein and in each document incorporated
              herein, constitute the sole and entire agreement between you and
              AxS with respect to the Site and the Services and supersede all
              prior and contemporaneous understandings, agreements,
              representations and warranties, both written and oral, with
              respect to the Site and the Services. The Terms of Use cannot be
              orally modified by AxS, or any of its employees, agents, or
              representatives.
            </p>
            <ol>
              <li>EFFECTIVE DATE; CHANGES TO THE TERMS OF USE</li>
            </ol>
            <p>
              The Terms of Use are effective as of the effective date, as set
              forth at the top hereof and as specifically set forth at the top
              of each document incorporated into the Terms of Use. AxS reserves
              the right to change the terms of this Terms of Use at any time. If
              we make a change, we will post the new terms on the Site and
              revise the effective date at the top of this page. We will provide
              a notification to registered users the next time they login to the
              Services with the revised Terms of Use and users will be asked to
              acknowledge that they have read and accept the changes by clicking
              "I Agree." We recommend that you read this Terms of Use each time
              you use our Site in case you missed our notice of changes. Your
              continued use of the Services and Site following the posting of
              changes to this Terms of Use will mean you accept those changes.
            </p>
            <p>
              If you have questions, concerns or comments regarding this Privacy
              Policy, please contact us at contact@axshealthapp.com or at:
            </p>
            <p>AxS Healthcare, Inc.</p>
            <p>1901 W. Lugonia Ave Suite 340</p>
            <p>Redlands, CA 92374</p>
          </div>

          <div id='privacypolicy' className='privacy-policy-content'>
            <a name='Privacy-Policy' />
            <h2>
              <strong>PRIVACY POLICY</strong>
            </h2>
            <p>Effective Date: August 1, 2017</p>
            <p>
              AxS Healthcare, Inc. ("AxS", "us", "we", or "our") respects your
              privacy and we are committed to protecting the privacy of the
              visitors and users of the AxSHealthapp.com (the "Site") and
              related websites, applications, services and mobile applications
              provided by AxS Healthcare, Inc. in which this Privacy Policy
              (this "Privacy Policy") is posted or referenced (collectively, the
              "Services").
            </p>
            <p>
              BY ACCESSING THE SITE AND USING THE SERVICES, YOU AGREE TO THE
              PRACTICES AND POLICIES OUTLINED IN THIS PRIVACY POLICY AND YOU
              HEREBY CONSENT TO THE COLLECTION, USE, AND SHARING OF YOUR
              INFORMATION AS DESCRIBED IN THIS PRIVACY POLICY. IF YOU DO NOT
              AGREE WITH THIS PRIVACY POLICY, YOU CANNOT USE THE SERVICES. IF
              YOU USE THE SERVICES ON BEHALF OF SOMEONE ELSE (SUCH AS YOUR
              CHILD) OR AN ENTITY (SUCH AS YOUR EMPLOYER), YOU REPRESENT THAT
              YOU ARE AUTHORIZED BY SUCH INDIVIDUAL OR ENTITY TO ACCEPT THIS
              PRIVACY POLICY ON SUCH INDIVIDUAL'S OR ENTITY'S BEHALF.
            </p>
            <p>
              Your use of the Services is governed by this Privacy Policy and
              the Terms of Use (as such term is defined in our Terms of Use).
              Any capitalized term used but not defined in this Privacy Policy
              shall have the meaning in the Agreement.
            </p>
            <ol>
              <li>INTRODUCTION</li>
            </ol>
            <p>
              This Privacy Policy describes the types of information we may
              collect from you or that you may provide when you use the Services
              and our practices for collecting, using, maintaining, protecting
              and disclosing that information. This Privacy Policy is only
              applicable to the Services and information we collect:
            </p>
            <ul>
              <li>On the Site</li>
              <li>
                In e-mail, text and other electronic messages between you and
                the Site
              </li>
              <li>
                Through mobile and desktop applications you download from the
                Site which provide dedicated non-browser-based interaction
                between you and this Website
              </li>
            </ul>
            <p>
              This Privacy Policy does not apply to any other website or digital
              service that you may be able to access through the Site or any
              website or digital services of AxS's business partners, each of
              which may have data collection, storage and use practices and
              policies that may materially differ from this Privacy Policy.
            </p>
            <ol>
              <li>INFORMATION WE COLLECT</li>
            </ol>
            <p>Personal Information</p>
            <p>
              When you access the Services, we may ask you to voluntarily
              provide us with certain information that personally identifies or
              could be used to personally identify you ("Personal Information").
              Personal Information includes (but is not limited to) the
              following categories of information:
            </p>
            <ul>
              <li>
                Contact information, such as your name, address, e-mail address
                and phone number;
              </li>
              <li>
                Demographic data, such as your gender, date of birth and zip
                code;
              </li>
              <li>
                Insurance data, such as your insurance carrier, insurance plan,
                member ID, group ID and payer ID;
              </li>
              <li>
                Payment information, such as your credit card number and billing
                address;
              </li>
              <li>
                Medical data, such as the reasons for your visit, the dates of
                your visits, your medical history, and other medical and health
                information you choose to share with us; and
              </li>
              <li>
                Other information about you that you voluntarily choose to
                provide to us.
              </li>
            </ul>
            <p>
              We may also collect additional information, which may be Personal
              Information, as otherwise described to you at the point of
              collection or pursuant to your consent.
            </p>
            <p>Billing, Collection and Payment Information</p>
            <p>
              When you make a payment through our Services to a Healthcare
              Provider (as further described in our Sale Terms), your payment
              card information is processed by our payment processing partner,
              Stripe ( <a href='https://stripe.com/'>https://stripe.com</a>).
              Stripe collects your voluntarily provided payment card information
              necessary to process your payment. Stripe's use and storage of
              information it collects is governed by Stripe's applicable terms
              of service and privacy policy. The information we store includes
              your payment card type and the last four digits of the payment
              card. We may provide to you the option to remove your stored
              payment card information through your account settings page.{' '}
            </p>
            <p>Protected Health Information and HIPAA</p>
            <p>
              Under a federal law called the Health Insurance Portability and
              Accountability Act ("HIPAA"), some of the contact, demographic,
              payment, and health and/or healthcare-related information that AxS
              collects as part of providing the Services may be considered
              "Protected Health Information" or "PHI". When AxS receives health
              and healthcare-related information that identifies you, or could
              be used to identify you, from either you or your Healthcare
              Providers, this information is PHI. If you elect to enter
              information into a Health Profile form ("Health Profile"), you
              authorize us to provide such information to your Healthcare
              Provider. You acknowledge and agree that such information will be
              reviewed by you prior to submission to ensure its accuracy. You
              also acknowledge that AxS may use the data or information you
              provide in your Health Profile in accordance with the terms of
              this Privacy Policy. HIPAA provides specific protections for the
              privacy and security of PHI and restricts how PHI is used and
              disclosed. AxS may only use and disclose your PHI in the ways
              permitted by your Healthcare Provider(s) and HIPAA. Your
              Healthcare Provider may provide you with their own Notice of
              Privacy Practices.
            </p>
            <p>Traffic Data</p>
            <p>
              We also may automatically collect the following types of data when
              you use the Services: (1) IP address; (2) domain server; (3) type
              of device(s) used to access the Services; (4) web browser(s) used
              to access the Services; (5) referring webpage or other source
              through which you accessed the Services; (6) geolocation
              information; and (7) other statistics and information associated
              with the interaction between your browser or device and the
              Services (collectively "Traffic Data"). Depending on applicable
              law, some Traffic Data may be Personal Information.
            </p>
            <ol>
              <li>HOW WE COLLECT INFORMATION</li>
            </ol>
            <p>Collection of Information</p>
            <p>
              We collect information (including Personal Information, PHI and
              Traffic Data) when you use and interact with the Services, and in
              some cases from third party sources. Such information includes:
            </p>
            <ul>
              <li>
                When you use the Services' interactive tools and services, such
                as searching for Healthcare Providers, searching for available
                appointments with Healthcare Providers and completing the Health
                Profile prior to Healthcare Provider appointments;
              </li>
              <li>
                When you voluntarily provide information in free-form text boxes
                or in uploaded documents, pictures or medical records through
                the Services;
              </li>
              <li>When you respond to surveys or questionnaires from us;</li>
              <li>
                If you download and install certain applications and software we
                make available, we may receive and collect information
                transmitted from your computing device for the purpose of
                providing you the relevant Services, such as information that
                lets AxS know when you are logged on and available to receive
                update or alert notices;
              </li>
              <li>
                If you download our mobile application, we may receive
                information about your location and mobile device;
              </li>
              <li>
                Through cookies, web beacons, website analytics services and
                other tracking technology (collectively, "Tracking Tools"), as
                described below; and
              </li>
              <li>
                When you use the "Contact Us" function on the Site, send us an
                email or otherwise contact us.
              </li>
            </ul>
            <p>Tracking Tools</p>
            <p>
              We may use tools outlined below in order to better understand
              users.
            </p>
            <ul>
              <li>
                Cookies: "Cookies" are small computer files transferred to your
                computing device that contain information such as user ID, user
                preferences, lists of pages visited and activities conducted
                while using the Services. We use Cookies to help us improve or
                tailor the Services by tracking your navigation habits, storing
                your authentication status so you do not have to re-enter your
                credentials each time you use the Services, customizing your
                experience with the Services and for analytics and fraud
                prevention.
              </li>
              <li>
                We may use a type of advertising commonly known as
                interest-based or online behavioral advertising. This means that
                some of our business partners may use Cookies to display AxS ads
                on other websites and services based on information about your
                use of the Services and your interests (inferred from your
                online activity). Other Cookies used by our business partners
                may collect information when you use the Services, such as the
                IP address, mobile device ID, operating system, browser, web
                page interactions, the geographic location of your internet
                service provider and demographic information, such as gender and
                age range. These Cookies help AxS learn more about our users'
                demographics and internet behaviors.
              </li>
            </ul>
            <p>
              For more information on cookies, visit{' '}
              <a href='http://www.allaboutcookies.org/'>
                http://www.allaboutcookies.org
              </a>
              .{' '}
            </p>
            <ul>
              <li>
                Web Beacons: "Web Beacons" (a.k.a. clear GIFs or pixel tags) are
                tiny graphic image files imbedded in a web page or email that
                may be used to collect anonymous information about your use of
                our Services, the websites of selected advertisers and the
                emails, special promotions or newsletters that we send you. The
                information collected by Web Beacons allows us to analyze how
                many people are using the Services, using the selected
                advertisers' websites or opening our emails, and for what
                purpose, and also allows us to enhance our interest-based
                advertising.
              </li>
              <li>
                Website Analytics: We may use third-party website analytics
                services in connection with the Services, including, for
                example, to register mouse clicks, mouse movements, scrolling
                activity and text that you type into the Site. These website
                analytics services generally do not collect Personal Information
                or PHI through text that you type while using the telemedicine
                related Services, but may collect usage data, such as whether or
                not you provided a response or how much of your Health Profile
                is completed. We use the information collected from these
                services to help make the Services easier to use and as
                otherwise set forth in Section 4, Use of Information (below).
              </li>
              <li>
                Mobile Device Identifiers: Mobile device identifiers are data
                stored on your mobile device that may track mobile device and
                data and activities occurring on and through it, as well as the
                applications installed on it. Mobile device identifiers enable
                collection of Personal Information (such as media access
                control, address and location) and Traffic Data. As with other
                Tracking Tools, mobile device identifiers help AxS learn more
                about our users' demographics and internet behaviors.
              </li>
            </ul>
            <p>Third-Party Use of Cookies or Tracking Tools</p>
            <p>
              Some content or applications, including advertisements, on the
              Site may be served by third parties, including advertisers, ad
              networks and servers, content providers and application providers.
              These third parties may use cookies, web beacons or other tracking
              technologies to collect information about you when you use our
              Site. The information they collect may be associated with your
              Personal Information or they may collect personally identifiable
              information about your online activities over time and across
              different websites and other online services. They may use this
              information to provide you with interest-based (behavioral)
              advertising or other targeted content on other websites.
            </p>
            <p>
              We do not control these third parties' tracking technologies or
              how they may be used. If you have any questions about an
              advertisement or other targeted content, you should contact the
              responsible provider directly. We have provided information about
              how you can opt out of receiving targeted advertising from many
              providers below.
            </p>
            <p>
              Options for Opting out of Cookies and Mobile Device Identifiers
            </p>
            <p>
              Some web browsers (including some mobile web browsers) allow you
              to reject Cookies or to alert you when a Cookie is placed on your
              computer, tablet or mobile device. You may be able to reject
              mobile device identifiers by activating the appropriate setting on
              your mobile device. Although you are not required to accept AxS'
              Cookies or mobile device identifiers, if you block or reject them,
              you may not have access to all features available through the
              Services.
            </p>
            <p>
              You may opt out of receiving certain Cookies and certain trackers
              by visiting the Network Advertising Initiative ("NAI") opt out
              page or the Digital Advertising Alliance ("DAA") consumer opt-out
              page. When you use these opt-out features, an "opt-out" Cookie
              will be placed on your computer or tablet indicating that you do
              not want to receive interest-based advertising from NAI or DAA
              member companies. If you delete Cookies on your computer or
              tablet, you may need to opt out again. For information about how
              to opt out of interest-based advertising on mobile device
              identifiers, please visit{' '}
              <a href='http://www.applicationprivacy.org/expressing-your-behavioral-advertising-choices-on-a-mobile-device'>
                http://www.applicationprivacy.org/expressing-your-behavioral-advertising-choices-on-a-mobile-device
              </a>
              .{' '}
            </p>
            <p>
              Please note that even after opting out of interest-based
              advertising, you may still see AxS' advertisements that are not
              interest-based or targeted specifically toward you. ). If you opt
              out of interest-based advertisements, AxS may still collect
              information about your use of the Services and may still serve
              advertisements to you via the Services based on information it
              collects through the Services.
            </p>
            <p>Browser "Do Not Track" Signals</p>
            <p>
              Some web browsers (including Safari, Internet Explorer, Firefox
              and Chrome) incorporate a "Do Not Track" (DNT) or similar feature
              that signals to websites that a visitor does not want to have
              his/her online activity and behavior tracked. If a website
              operator elects to respond to a particular DNT signal, the website
              operator may refrain from collecting certain Personal Information
              about the browser's user. Not all browsers offer a DNT option and
              there is currently no industry consensus as to what constitutes a
              DNT signal. For these reasons, many website operators, including
              AxS, do not take action to respond to DNT signals. For more
              information about DNT signals, visit{' '}
              <a href='http://allaboutdnt.com/'>http://allaboutdnt.com</a>.{' '}
            </p>
            <ol>
              <li>USE OF INFORMATION</li>
            </ol>
            <p>
              We use your Personal Information to provide the Services to you
              and to help improve them, including to:
            </p>
            <ul>
              <li>
                Provide you with the Services and other products, services and
                information you request and to respond to correspondence that we
                receive from you;
              </li>
              <li>
                Provide, maintain, administer or expand the Services, perform
                business analyses, or for other internal purposes to support,
                improve or enhance our business, the Services, and other
                products and services we offer;
              </li>
              <li>
                Notify you about certain resources or Healthcare Providers we
                think you may be interested in learning more about;
              </li>
              <li>
                Send you information about AxS or our products or Services;
              </li>
              <li>
                Contact you when necessary or requested, including to remind you
                of an upcoming appointment;
              </li>
              <li>
                Customize and tailor your experience of the Services, which may
                include sending customized messages or showing you Sponsored
                Offers related to goods or services that may be of interest to
                you based on information collected in accordance with this
                Privacy Policy;
              </li>
              <li>
                Send emails and other communications that display content that
                we think will interest you and according to your preferences;
              </li>
              <li>
                We may use the information we have collected from you to enable
                us to display advertisements to our advertisers' target
                audiences. Even though we do not disclose your Personal
                Information for these purposes without your consent, if you
                click on or otherwise interact with an advertisement, the
                advertiser may assume that you meet its target criteria;
              </li>
              <li>
                Combine information received from third parties with the
                information that we have from or about you and use the combined
                information for any of the purposes described in this Privacy
                Policy;
              </li>
              <li>
                Use non-individually identifiable statistical information that
                we collect in any way permitted by law, including with third
                parties in connection with their commercial and marketing
                efforts; and
              </li>
              <li>
                Prevent, detect and investigate security breaches and
                potentially illegal or prohibited activities.
              </li>
            </ul>
            <p>
              We may use information that is neither Personal Information nor
              PHI (including information that has been de-identified and
              aggregated) to better understand who uses AxS and how we can
              deliver a better healthcare experience (for example, testing
              different kinds of emails has helped us understand when and how
              patients prefer to get Wellness Reminders for preventive care), or
              otherwise at our discretion.
            </p>
            <ol>
              <li>DISCLOSURE OF INFORMATION</li>
            </ol>
            <p>
              We may disclose your Personal Information, PHI and certain
              information that you provide to us or we collect from you as
              follows:
            </p>
            <ul>
              <li>
                To the Healthcare Providers with which you choose to schedule
                through the Services for the purposes of:{' '}
                <ul>
                  <li>Their provisions of treatment to you;</li>
                  <li>
                    Enabling them to refer you to, or make appointments with,
                    other Healthcare Providers on your behalf; or
                  </li>
                  <li>
                    Such Healthcare Provider's health care operations, including
                    conducting quality assessment review or service improvement
                    planning activities.
                  </li>
                </ul>
              </li>
              <li>
                To the insurance provider you identify to us (via our business
                partners), we may share your insurance-related Personal
                Information for the purposes of determining eligibility and
                cost-sharing obligations, and otherwise obtaining insurance
                benefit plan information,
              </li>
              <li>
                We may share your Personal Information and PHI with Healthcare
                Providers in the event of an emergency.
              </li>
              <li>
                We may share your Personal Information (but not PHI) with
                organizations that collect, aggregate and organize your
                information so they can make it more easily accessible to your
                Healthcare Providers.
              </li>
              <li>
                We do not sell email addresses to third parties. You may
                voluntarily provide your e-mail address at your option to other
                providers who have referral links to their website from our
                Services.
              </li>
              <li>
                We may share your Personal Information and Traffic Data with our
                business partners who perform core operational services for AxS
                (such as hosting, billing, fulfillment, data storage, security,
                insurance verification, or Website analytics set forth in
                Section 3 (Tracking Tools)) and/or by making certain features
                available to our users.
              </li>
              <li>
                We may transfer your information to another company in
                connection with a merger, sale, acquisition or other change of
                ownership or control by or of AxS (whether in whole or in part).
                Should one of these events occur, we will make reasonable
                efforts to notify you before your information becomes subject to
                different privacy and security policies and practices.
              </li>
              <li>
                We also may need to disclose your Personal Information, PHI or
                any other information we collect about you if we determine in
                good faith that such disclosure is needed to: (1) comply with
                applicable law, regulation, court order or other legal process;
                (2) to prevent or lessen a serious and imminent threat to the
                health or safety of you, another person or the public; (3)
                enforce the Terms of Use with you; or (4) respond to claims that
                any posting or other content violates third-party rights.
              </li>
            </ul>
            <p>
              We may disclose information that is neither Personal Information
              nor PHI (including information that has been de-identified and
              aggregated) at our discretion. We may also disclose de-identified
              usage data to provide feedback on the Services to participating
              Healthcare Providers and/or to employers or insurance plan that
              contract with use to make our Services available to their
              employees or participants.
            </p>
            <ol>
              <li>STORAGE AND SECURITY OF INFORMATION</li>
            </ol>
            <p>
              We store and process your information on our servers in the United
              States.. We maintain industry standard backup and archival
              systems.
            </p>
            <p>
              We endeavor to follow generally accepted industry standards to
              protect the Personal Information and PHI submitted to us, both
              during transmission and in storage. All data is stored in
              encrypted format that meets standards defined by HIPAA. All data
              transfers are executed using secure socket layer technology.
            </p>
            <p>
              Although we make good faith efforts to store Personal Information
              in a secure operating environment that is not open to the public,
              we do not and cannot guarantee the security of your Personal
              Information. If we become aware that your Personal Information has
              been disclosed in a manner not in accordance with this Privacy
              Policy, we will use reasonable efforts to notify you of the nature
              and extent of the disclosure (to the extent we know that
              information) as soon as reasonably possible and as permitted or
              required by law.
            </p>
            <ol>
              <li>AMENDING YOUR PERSONAL INFORMATION</li>
            </ol>
            <p>
              If you are a registered user of the Services, you may review and
              modify the information you entered into your Health Profile.. You
              can modify your other Personal Information, including your
              username and password, by accessing the settings for your account.
            </p>
            <p>
              If you wish to close your account, please email us at
              contact@axshealthapp.com. We will delete your account and all of
              your Personal Information at your request as soon as reasonably
              possible. Please know that AxS does reserve the right to retain
              certain information from closed accounts in order to comply with
              law, prevent fraud, resolve disputes, enforce the Terms of Use and
              take other actions permitted by law.
            </p>
            <ol>
              <li>CHANGES TO THIS PRIVACY POLICY</li>
            </ol>
            <p>
              AxS reserves the right to change the terms of this Privacy Policy
              at any time. The process for changes to this Privacy Policy are
              described in our Terms of Use. If we change the terms of this
              Privacy Policy, the new terms will apply to all Personal
              Information AxS maintains, including information that was created
              or received before such changes were made.
            </p>
            <ol>
              <li>CONTACTS</li>
            </ol>
            <p>
              If you have questions, concerns or comments regarding this Privacy
              Policy, please contact us at contact@axshealthapp.com or at:
            </p>
            <p>AxS Healthcare, Inc.</p>
            <p>1901 W. Lugonia Ave Suite 340</p>
            <p>Redlands, CA 92374</p>
            <a name='Acceptable-Use' />
            <h2>
              <strong>ACCEPTABLE USE POLICY</strong>
            </h2>
            <p>Effective Date: December 15th, 2015</p>
            <p>
              This Acceptable Use Policy ("AUP") is incorporated by reference
              into AxS Healthcare, Inc.'s ("AxS," "we," "us" or "our") Terms of
              Use and, along with the other Terms of Use, governs your use of
              the Site and Services (as such terms are defined in the Terms of
              Use). Any capitalized term used but not defined in this AUP shall
              have the meaning assigned in the Terms of Use.
            </p>
            <p>
              BY VISITING, USING, REGISTERING, OR OTHERWISE ACCESSING THE SITE
              AND THE SERVICES, YOU AGREE TO BE BOUND BY THE TERMS OF USE
              (INCLUDING THIS AUP); IF YOU DO NOT AGREE, DO NOT USE THE SITE OR
              THE SERVICES.
            </p>
            <ol>
              <li>GENERAL; PROHIBITED USES</li>
            </ol>
            <p>
              AxS determines acceptable behavior with respect to the Services as
              set forth in this AUP and elsewhere in the Terms of Use. As part
              of your access to the Site and use of the Services, you agree that
              you will not:
            </p>
            <ul>
              <li>use the Service in any unlawful manner;</li>
              <li>fraudulently or intentionally misuse the Services;;</li>
              <li>
                use the Services in any manner that is harmful, hateful,
                harassing, abusive, or otherwise offensive to any third party
                (including your Healthcare Provider), in AxS' sole discretion;
              </li>
              <li>
                interfere with or inhibit any other user from using or enjoying
                the Services;
              </li>
              <li>
                use the Services as a means to distribute unsolicited or
                unauthorized communications, advertisements, or spam;
              </li>
              <li>
                access or search the Services by any means other than the
                authorized public interface;
              </li>
              <li>
                collect or use any Content that violates any third-party right
                (whether a privacy right, an intellectual property right, or
                otherwise);
              </li>
              <li>
                create a false identity, impersonate another person or entity,
                otherwise misrepresent yourself, or create user accounts, book
                appointments or submit user-generated content by automated means
                or under false or fraudulent pretenses;
              </li>
              <li>
                breach or otherwise circumvent any security measures
                incorporated into the Site or the Services; or
              </li>
              <li>
                use the Services in any manner that is considered unacceptable
                by AxS.
              </li>
            </ul>
            <p>
              Without limiting the foregoing, you also agree you will not, and
              will not attempt to:
            </p>
            <ul>
              <li>
                interfere with or disrupt the functioning of the Services in any
                manner, including the functioning of any software, hardware,
                network or server connected to the Site or the Services;
              </li>
              <li>
                attempt to gain unauthorized access to, interfere with, damage
                or disrupt any parts of the Site, the server on which the Site
                is stored, or any server, computer or database connected to the
                Site;
              </li>
              <li>
                distribute or transfer in any manner the Site or the Services or
                any part of the Services to any third party;
              </li>
              <li>
                modify, reformat, adapt, or translate the Site or the Services,
                the Content, or any part of the Services for any purpose;
              </li>
              <li>
                use any robot, spider, site search/retrieval application, or
                other automated device, process or means to access, retrieve,
                index, monitor, or copy any of the Site, the Content, or any
                Services;
              </li>
              <li>
                introduce any viruses, trojan horses, worms, logic bombs or
                other material which is malicious or technologically harmful;
              </li>
              <li>
                attack the Website via a denial-of-service attack or a
                distributed denial-of-service attack.
              </li>
              <li>
                use any means, including software means, to conduct web scraping
                of any portion of the Site, the Content, or the Services.
              </li>
              <li>
                incorporate by any means any of the Content into another
                application, website or service;
              </li>
              <li>
                reverse-engineer or create derivative works based on any part of
                the Services for any purpose, commercial or otherwise;
              </li>
              <li>
                visit the Site or use the Services in any manner (commercial or
                otherwise) other than for your personal use as a patient or
                prospective patient of a Healthcare Provider and customer of
                AxS;
              </li>
              <li>
                use the Services, directly or indirectly, to engage in any
                anti-competitive, deceptive or unfair practices, or otherwise
                violate applicable antitrust, competition or consumer protection
                laws, or regulations;
              </li>
              <li>
                collect or store personal data about other users in connection
                with the prohibited activities described in this AUP; or
              </li>
              <li>
                visit the Site or use the Services in any manner that is
                inconsistent with the Terms of Use.
              </li>
            </ul>
            <p>
              Our policy is to block access to or remove material that we
              believe in good faith to be copyrighted material that has been
              illegally copied and distributed by any of our advertisers,
              affiliates, Content providers, members or other users. We may deny
              access to the Site and the Services to those who violate this AUP.
            </p>
            <ol>
              <li>USER GENERATED CONTENT</li>
            </ol>
            <p>
              As part of the Services, registered users may submit feedback to
              AxS on Healthcare Providers (the "Feedback"), may have the ability
              to post ratings based on interactions with Healthcare Providers
              through the Services (the "Ratings") and may have the ability to
              submit information about their health condition, including
              information in the Health Profile and any documents or pictures
              ("Medical Information"). All Feedback, Ratings and Medical
              Information (the "User Content") must comply with this AUP and the
              other Terms of Use. All Feedback, Ratings and Medical Information
              must be honest, true and made in good-faith based on your use of
              the Site and the Services.
            </p>
            <p>
              You acknowledge and understand that the Feedback will be used
              internally by AxS and will not be viewable by the public, visitors
              to the Site, or other registered users. You further acknowledge
              and agree that the Ratings may be viewable by other registered
              users of the Site and the Services. Like any Ratings you provide,
              which reflect your opinion, other Ratings reflect the opinions of
              other users and you should not rely on those Ratings as an
              indicator of the Healthcare Provider or a guaranty of a certain
              outcome. We reserve the right to publish your Ratings as part of
              the Services and remove your Ratings for any reason. We are not,
              however, responsible for any failure or delay in posting or
              removing any Ratings.
            </p>
            <p>
              Without limiting the generality of the foregoing, this AUP, and
              the other Terms of Use, you acknowledge and agree that, with
              respect to all User Content:
            </p>
            <ul>
              <li>
                you are solely responsible for any Feedback, Ratings or Medical
                Information that you submit, publish or display through the
                Services (as applicable);
              </li>
              <li>
                you may not submit any content or material that infringes,
                misappropriates or violates the intellectual property,
                publicity, privacy or other rights of any party;
              </li>
              <li>
                you may not submit Feedback or Ratings that in any way:{' '}
                <ul>
                  <li>
                    are false, deceptive or misleading, or likely to deceive or
                    mislead, in our sole discretion;
                  </li>
                  <li>
                    are harassing, offensive, discriminatory, sexually explicit,
                    threatening, abusive, obscene, defamatory or libelous, in
                    our sole discretion;
                  </li>
                  <li>
                    involves the transmission of unsolicited mass mailing or
                    "spamming";
                  </li>
                  <li>
                    violates, infringes or misappropriates the intellectual
                    property or other rights of any person or entity;
                  </li>
                  <li>
                    promotes illegal activity or advocates or assists in
                    unlawful conduct; or
                  </li>
                  <li>
                    violates antitrust, competition, or consumer protection
                    laws.
                  </li>
                </ul>
              </li>
            </ul>
            <p>
              We reserve the right to remove User Content to the extend it
              violates any of the above agreements and/or to terminate your
              account. The foregoing is a partial list of the kind of content
              and communications that are illegal or prohibited on/through the
              Site. AxS reserves the right to investigate and take appropriate
              legal action in accordance with the{' '}
              <a href='http://www.axshealthapp.com/terms-of-use.php#termsofuse'>
                Terms of Use
              </a>
              .{' '}
            </p>
            <ol>
              <li>CONTACTS</li>
            </ol>
            <p>
              If you have questions, concerns or comments regarding this AUP,
              please contact us at contact@axshealthapp.com or at:
            </p>
            <p>AxS Healthcare, Inc.</p>
            <p>1901 W. Lugonia Ave Suite 340</p>
            <p>Redlands, CA 92374</p>
            <a name='Sale-And-Addl-Terms' />
            <h2>
              <strong>SALE AND ADDITIONAL TERMS</strong>
            </h2>
            <p>Effective Date: August 1, 2017</p>
            <p>
              This policy on Sale and Additional Terms (the "Sale and Additional
              Terms") is incorporated by reference into AxS Healthcare, Inc.'s
              ("AxS", "us", "we", or "our"){' '}
              <a href='http://www.axshealthapp.com/terms-of-use/'>
                Terms of Use
              </a>{' '}
              (the "Terms of Use") and governs the billing and payment terms
              related to your use of the Site and the Services. The Sale and
              Additional Terms also includes additional terms and conditions
              related to your use of the Site and the Services.{' '}
            </p>
            <p>
              BY USING OR OTHERWISE ACCESSING THE SERVICES, AND/OR BY
              REGISTERING WITH US, YOU ACKNOWLEDGE AND AGREE THAT YOU HAVE READ
              THESE SALES TERMS, UNDERSTAND THEM, AND AGREE TO BE LEGALLY BOUND
              BY THEM. IF YOU DO NOT AGREE WITH THESE ADDITIONAL TERMS, YOU
              CANNOT USE THE SERVICES. Any capitalized term used but not defined
              in these Additional Terms shall have the meaning in our Terms of
              Use.
            </p>
            <ol>
              <li>FEES AND PURCHASE TERMS</li>
            </ol>
            <p>
              The fees for telemedicine consultations with Healthcare Providers
              can be found upon registering for our Services. Fees are subject
              to change, and any changes will be posted for registered users on
              our Site. It is your responsibility to remain informed about the
              current rates. You must resolve any dispute between you or any
              Healthcare Provider arising from any transaction provided through
              the Services directly with the Healthcare Provider.
            </p>
            <p>
              You will be required to provide AxS with a valid credit card or
              any other method of payment accepted by us ("Payment Provider")
              when you set up your billing account within our Services ("Your
              Account"). By providing AxS with your credit card information or
              Payment Provider and associated payment information, you agree
              that AxS is authorized to bill Your Account at the time any
              payments are due and to charge your designated credit card or
              Payment Provider account. If any charges are subject to sales tax
              in the applicable jurisdiction, you also agree to pay the required
              sales tax and any related penalties or interest. AxS may
              automatically charge sales taxes for addresses within
              jurisdictions where such sales taxes are required. You are
              responsible for all fees and charges associated with Your Account.
            </p>
            <p>
              If you cancel an appointment within 24 hours of the appointment
              time or do not use or report for your appointment, you will still
              be charged a fee for cancelled appointments. Except as stated in
              the Sale or Additional Terms or as otherwise agreed to in writing
              by AxS, all fees paid are non-refundable.
            </p>
            <p>
              We use a third-party payment processor (the "Payment Processor")
              to charge your credit card or Payment Provider account. The
              processing of payments in connection with your use of our Services
              will be subject to the terms, conditions and privacy policies of
              the Payment Processor and the Payment Provider responsible for
              your designated method of payment, in addition to AxS' Privacy
              Policy and Terms of Use. You will promptly review all charges
              processed through the Transaction Processing Services, and
              immediately notify AxS Health to the extent you have any
              questions, concerns or disputes with respect to AxS' charges. We
              are not responsible for any errors by the Payment Processor or the
              Payment Provider.
            </p>
            <p>
              You acknowledge and agree that neither AxS nor our Payment
              Processer will be responsible in any manner if either we or our
              Payment Processer are unable to complete a payment transaction for
              any reason, including but not limited to:
            </p>
            <ol>
              <li>
                IF YOU HAVE NOT PROVIDED US WITH ACCURATE, CURRENT AND COMPLETE
                PAYMENT INFORMATION;
              </li>
              <li>
                IF YOU DO NOT HAVE SUFFICIENT AVAILABLE FUNDS OR AVAILABLE
                CREDIT TO COMPLETE THE TRANSACTION;
              </li>
              <li>
                IF YOU DO NOT HAVE AN ACTIVE PAYMENT CARD, OR IF WE ARE UNABLE
                TO CONFIRM YOUR PAYMENT CARD INFORMATION OR YOUR IDENTITY;
              </li>
              <li>
                IF YOUR ACCESS TO THE SERVICES, YOUR ACCOUNT WITH US OR YOUR
                ACCOUNT WITH YOUR PAYMENT PROVIDER HAS BEEN TERMINATED OR
                SUSPENDED FOR ANY REASON;
              </li>
              <li>
                IF THE SERVICE PROVIDED BY OUR PAYMENT PROCESSER IS DOWN OR
                AVAILABLE FOR ANY REASON;
              </li>
              <li>
                IF WE OR OUR PAYMENT PROCESSER HAVE REASON TO BELIEVE THAT THE
                REQUESTED TRANSACTION IS UNAUTHORIZED; OR
              </li>
              <li>
                IF WE HAVE TERMINATED OR SUSPENDED THE SERVICES WE PROVIDE TO
                THE APPLICABLE HEALTHCARE PROVIDER.
              </li>
              <li>DESIGNATED PROVIDER SERVICES.</li>
            </ol>
            <p>
              Certain Healthcare Providers may provide through the Services
              price information for their healthcare and related products and
              services ("Designated Provider Services"), and permit you to use
              the Services to pay for such Designated Provider Services.
            </p>
            <p>
              Prior to scheduling an appointment for Designated Provider
              Services, please carefully review (a) the price information, and
              (b) all of the products and services that are included and
              excluded in each Designated Provider Service. Healthcare Providers
              are required to provide the applicable Designated Provider Service
              at or below the price indicated at the time of scheduling
              ("Designated Price"), unless you separately agree otherwise.
              Services in addition to or different from Designated Provider
              Services may be available, offered or rendered by Healthcare
              Providers. You and your Healthcare Providers are responsible for
              agreeing to any additional or different services. WE ARE NOT
              RESPONSIBLE FOR YOUR OR ANY HEALTHCARE PROVIDER'S FAILURE TO AGREE
              TO ANY ADDITIONAL OR DIFFERENT SERVICES.
            </p>
            <ol>
              <li>
                PAYMENT OF YOUR FINANCIAL OBLIGATIONS UNDER YOUR INSURANCE
                COVERAGE AND BENEFITS
              </li>
            </ol>
            <p>
              Certain Healthcare Providers may permit you to use the Services to
              pay for all or a portion of your financial obligations under your
              insurance coverage and benefits (the "Insurance Payment
              Services"). While we endeavor to use reasonable efforts to
              validate your insurance information (for example, your copayment
              obligation), we make no guarantees and disclaim all warranties
              that the insurance information is correct since it is provided by
              the insurance provider or via a third-party clearinghouse. Please
              also keep in mind that (a) the insurance information is constantly
              being updated (for example, to reflect amounts you may have paid
              earlier towards your deductible); (b) you are likely to receive an
              explanation of benefits (or similar document) from you insurance
              company after you receive your Healthcare Provider's services; and
              (c) you may owe your Healthcare Provider more than the amount you
              paid through the Insurance Payment Services. You remain
              responsible for paying all amounts required by law and/or under
              your health insurance plan, including all cost-sharing obligations
              (such as, but not limited to, copayments, deductibles and other
              coinsurance obligations).
            </p>
            <p>
              If you do not have insurance coverage for the telemedicine
              consultation, then you are responsible for the full fee charged
              for the Healthcare Provider's care through our Services. If you
              choose not to provide any insurance information, then it is solely
              up to you to contact your health insurance plan, determine whether
              your coverage applies, and submit a claim if applicable. In such
              event, you are responsible for the full fee charged for the
              Healthcare Provider's care through our Services regardless of
              whether you are able to receive reimbursement from your health
              insurance plan for part or all of the cost.
            </p>
            <ol>
              <li>DEVICE COMPATIBILITY</li>
            </ol>
            <p>
              AxS Company does not promise that the Site and the Services are
              compatible with all devices and with all versions/updates of all
              operating systems or firmware. If you update your operating system
              or firmware, your version of the application may not function
              properly. AxS may, but is not obligated to, provide updates to the
              application that improve compatibility with updated mobile
              devices.
            </p>
            <ol>
              <li>SMS/TEXT COMMUNICATIONS AND DATA CHARGES</li>
            </ol>
            <p>
              We may send communications to you on your mobile telephone by SMS
              or text message. Message and data rates from your mobile telephone
              service provider may apply and are subject to the terms and
              conditions imposed by your provider. Where the Site and the
              Service may require (or permit) use of wireless or cellular data
              to operate certain features or functions and transmit data, AxS is
              not responsible for your data access service or any charges
              related thereto. You are solely responsible for paying any
              applicable charges payable to your cellular, broadband and/or
              messaging provider(s).
            </p>
            <ol>
              <li>THIRD PARTY SOFTWARE</li>
            </ol>
            <p>
              We may incorporate third party software as part of certain of the
              Services, including without limitation open source third party
              software. Your use of such third party software is subject to any
              and all applicable additional terms and conditions governing such
              use provided by the third party software provider. Where
              applicable, additional notices relating to the third party
              software may be provided by us, which for example may contain
              attribution and disclaimer notices applicable to the third party
              software.
            </p>
            <ol>
              <li>APPLE DEVICE AND APPLICATION TERMS</li>
            </ol>
            <p>
              In the event you are accessing the Services via an application
              ("Application") on a device provided by Apple, Inc. ("Apple") or
              an Application obtained through the Apple App Store, the following
              shall apply:
            </p>
            <ol>
              <li>
                BOTH YOU AND AXS ACKNOWLEDGE THAT THE AGREEMENT (AS DEFINED IN
                THE TERMS OF USE) ARE CONCLUDED BETWEEN YOU AND AXS ONLY, AND
                NOT WITH APPLE, AND THAT APPLE IS NOT RESPONSIBLE FOR THE
                APPLICATION OR THE CONTENT;
              </li>
              <li>
                THE APPLICATION IS LICENSED TO YOU PURSUANT TO THE TERMS AND
                CONDITIONS SET FORTH IN THE AGREEMENT SOLELY TO BE USED IN
                CONNECTION WITH THE SERVICE FOR YOUR PRIVATE, PERSONAL,
                NON-COMMERCIAL USE;
              </li>
              <li>
                YOU WILL ONLY USE THE APPLICATION IN CONNECTION WITH AN APPLE
                DEVICE THAT YOU OWN OR CONTROL;
              </li>
              <li>
                YOU ACKNOWLEDGE AND AGREE THAT APPLE HAS NO OBLIGATION
                WHATSOEVER TO FURNISH ANY MAINTENANCE AND SUPPORT SERVICES WITH
                RESPECT TO THE APPLICATION;
              </li>
              <li>
                IN THE EVENT OF ANY FAILURE OF THE APPLICATION TO CONFORM TO ANY
                APPLICABLE WARRANTY, INCLUDING THOSE IMPLIED BY LAW, YOU MAY
                NOTIFY APPLE OF SUCH FAILURE; UPON NOTIFICATION, APPLE'S SOLE
                WARRANTY OBLIGATION TO YOU WILL BE TO REFUND TO YOU THE PURCHASE
                PRICE, IF ANY, OF THE APPLICATION;
              </li>
              <li>
                YOU ACKNOWLEDGE AND AGREE THAT AXS, AND NOT APPLE, IS
                RESPONSIBLE FOR ADDRESSING ANY CLAIMS YOU OR ANY THIRD PARTY MAY
                HAVE IN RELATION TO THE APPLICATION;
              </li>
              <li>
                YOU ACKNOWLEDGE AND AGREE THAT, IN THE EVENT OF ANY THIRD PARTY
                CLAIM THAT THE APPLICATION OR YOUR POSSESSION AND USE OF THE
                APPLICATION INFRINGES THAT THIRD PARTY'S INTELLECTUAL PROPERTY
                RIGHTS, APPLE WILL NOT BE RESPONSIBLE FOR THE INVESTIGATION,
                DEFENSE, SETTLEMENT AND DISCHARGE OF ANY SUCH INFRINGEMENT
                CLAIM;
              </li>
              <li>
                YOU REPRESENT AND WARRANT THAT YOU ARE NOT LOCATED IN A COUNTRY
                SUBJECT TO A U.S. GOVERNMENT EMBARGO, OR THAT HAS BEEN
                DESIGNATED BY THE U.S. GOVERNMENT AS A "TERRORIST SUPPORTING"
                COUNTRY, AND THAT YOU ARE NOT LISTED ON ANY U.S. GOVERNMENT LIST
                OF PROHIBITED OR RESTRICTED PARTIES;
              </li>
              <li>
                BOTH YOU AND AXS ACKNOWLEDGE AND AGREE THAT, IN YOUR USE OF THE
                APPLICATION, YOU WILL COMPLY WITH ANY APPLICABLE THIRD PARTY
                TERMS OF AGREEMENT WHICH MAY AFFECT OR BE AFFECTED BY SUCH USE;
                AND
              </li>
              <li>
                BOTH YOU AND AXS ACKNOWLEDGE AND AGREE THAT APPLE AND APPLE'S
                SUBSIDIARIES ARE THIRD PARTY BENEFICIARIES OF CERTAIN TERMS THE
                AGREEMENT TO THE EXTENT SUCH TERMS RELATE TO AN APPLICATION ON A
                DEVICE PROVIDED BY APPLE OR AN APPLICATION OBTAINED THROUGH THE
                APPLE APP STORE, AND THAT UPON YOUR ACCEPTANCE OF THE AGREEMENT,
                APPLE WILL HAVE THE RIGHT (AND WILL BE DEEMED TO HAVE ACCEPTED
                THE RIGHT) TO ENFORCE SUCH TERMS OF THE AGREEMENT AGAINST YOU AS
                THE THIRD PARTY BENEFICIARY HEREOF.
              </li>
              <li>CONTACTS</li>
            </ol>
            <p>
              If you have questions, concerns or comments regarding the Sale and
              Additional Terms, please contact us at contact@axshealthapp.com or
              at:
            </p>
            <p>AxS Healthcare, Inc.</p>
            <p>1901 W. Lugonia Ave Suite 340</p>
            <p>Redlands, CA 92374</p>
            <p>
              <a name='Informed-Consent' />
            </p>
            <h2>
              <strong>INFORMED CONSENT OF TELEMEDICINE SERVICES</strong>
            </h2>
            <p>
              <span>
                Telemedicine uses electronic communications enabling healthcare
                to be delivered by a medical care provider in one location to a
                patient in another location. While this technology offers great
                convenience and in certain situations is a better means of
                healthcare delivery, recipients of telemedicine need to fully
                understand the limitations and risks associated with this
                technology.
              </span>
            </p>
            <p>
              <span>
                AxS Health telemedicine communications from your medical
                provider may be delivered via written messaging, video or a
                combination of the above. The content of the information
                exchanged may include patient medical records, personal health
                information and medical images. AxS Health incorporates network
                and software security protocols to protect the confidentiality
                of patient identification and imaging data and will include
                measures to safeguard the data to ensure its integrity against
                intentional or unintentional corruption or unauthorized access.
              </span>
            </p>
            <p>
              <span>Expected Benefits of Telemedicine:</span>
            </p>
            <ul>
              <li>
                <span>
                  Improved convenience, enabling a patient to remain in his/her
                  home or work while the medical care provider provides
                  professional advice
                </span>
              </li>
              <li>
                <span>Cost savings</span>
              </li>
            </ul>
            <p>
              <span>Possible Limitations of Telemedicine:</span>
            </p>
            <ul>
              <li>
                <span>
                  The absence of a face to face and hands-on examination
                  introduces the potential for important information to be
                  missed which may adversely affect the recommended treatment.
                </span>
              </li>
              <li>
                <span>
                  Delays in medical evaluation and treatment could occur due to
                  deficiencies or failures of the equipment.
                </span>
              </li>
              <li>
                <span>
                  In very rare instances, security protocols could fail, causing
                  a breach of privacy of personal medical information.
                </span>
              </li>
              <li>
                <span>
                  In rare cases, a lack of access to complete medical records
                  may result in adverse drug interactions, allergic reactions or
                  other undesirable outcomes.
                </span>
              </li>
              <li>
                <span>
                  In rare cases, the medical care provider may determine that
                  the transmitted information is of inadequate quality, thus
                  necessitating a face-to-face communication with the patient.
                </span>
              </li>
            </ul>
            <p>
              <span>
                By checking the box providing "Informed Consent", you
                acknowledge that you understand and agree with the following:
              </span>
            </p>
            <ol>
              <li>
                <span>
                  I understand that the laws that protect privacy and the
                  confidentiality of medical information also apply to
                  telemedicine. I understand that healthcare information
                  obtained in the use of telemedicine, which identifies me, will
                  be disclosed only as described in the HIPAA – Notice of
                  Privacy Practices.
                </span>
              </li>
              <li>
                <span>
                  I understand that I have the right to withhold or withdraw my
                  consent to the use of telemedicine in the course of my care at
                  any time, without affecting my right to future care or
                  treatment.
                </span>
              </li>
              <li>
                <span>
                  I understand the limitations of telemedicine and the absence
                  of a hands-on examination may affect the accuracy of the
                  diagnosis and appropriateness of the treatment plan offered.{' '}
                </span>
              </li>
              <li>
                <span>
                  I understand that telemedicine may involve electronic
                  communication of my personal medical information to medical
                  practitioners who may be located in other areas, including out
                  of state.
                </span>
              </li>
              <li>
                <span>
                  I understand that I may expect the anticipated benefits from
                  the use of telemedicine in my care, but that no results can be
                  guaranteed or assured.
                </span>
              </li>
              <li>
                <span>
                  I understand that a variety of alternative methods of medical
                  care are available to me and that I may choose one or more of
                  these at any time.
                </span>
              </li>
              <li>
                <span>
                  I understand that my healthcare information may be shared with
                  other individuals for scheduling and billing purposes. These
                  people are required to maintain confidentiality of the
                  healthcare information obtained.{' '}
                </span>
              </li>
              <li>
                <span>
                  I understand that it is my responsibility to provide my
                  telemedicine provider with detailed, accurate and complete
                  information concerning my medications, medical history, and
                  current symptoms.{' '}
                </span>
              </li>
            </ol>
          </div>
        </Col>
      </Row>
    </Container>
  )
}
