import React from 'react'

import Home from './_pages/Home/'
import About from './_pages/About/'
import Solutions from './_pages/Solutions/'
import Terms from './_pages/Terms/'

import MainLayout from './_layouts/Main'
import PublicRoute from './_helpers/PublicRoute'

import { history } from './_helpers/history'
import { Router, Switch } from 'react-router-dom'

import './style.css'

function App() {
  return (
    <Router history={history}>
      <Switch>
        <PublicRoute path='/home' layout={MainLayout} component={Home} />

        <PublicRoute path='/about' layout={MainLayout} component={About} />

        <PublicRoute
          path='/terms-of-use'
          layout={MainLayout}
          component={Terms}
        />

        <PublicRoute
          path='/solutions/:section'
          layout={MainLayout}
          component={Solutions}
        />

        <PublicRoute
          path='/solutions'
          layout={MainLayout}
          component={Solutions}
        />

        <PublicRoute exact path='/' layout={MainLayout} component={Home} />
      </Switch>
    </Router>
  )
}

export default App
