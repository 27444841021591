import React, { useState, useEffect } from 'react'
import TeamMember from './TeamMember'

import profile from '../../_assets/images/profile.jpg'

import './TeamSlider.css'

export default function TeamSlider() {
  const [show, setShow] = useState(false)
  const [current, setCurrent] = useState(0)

  useEffect(() => {
    setTimeout(() => {
      setShow(true)
      loopSlides(current)
    }, 400)
  }, [])

  const loopSlides = (i) => {
    setInterval(() => {
      ++i
      setCurrent(i % 4)
    }, 4000)
  }

  const animate = (status) => {
    switch (status % 4) {
      case 0:
        return 'team__member--selected'
      case 1:
        return 'team__member--hidden'
      case 2:
        return 'team__member--first'
      case 3:
        return 'team__member--second'
      default:
    }
  }

  return (
    <div className={`${show && 'team__slider--show'} team__slider--container`}>
      <TeamMember
        img='https://s3.amazonaws.com/axs.images/axs_fileuq53Ho1560894491_org.jpg'
        name='John Steinmann'
        title='Founder/CEO'
        status={animate(current + 0)}
      />
      <TeamMember
        img={profile}
        name='Matthew Kim'
        title='Front-End Developer'
        status={animate(current + 1)}
      />
      <TeamMember
        img={profile}
        name='Raul Tabares'
        title='CTO'
        status={animate(current + 2)}
      />
      <TeamMember
        img={profile}
        name='Angela Carlson'
        title='COO'
        status={animate(current + 3)}
      />
    </div>
  )
}
