import React, { Component } from 'react'

import NavBar from '../../_components/NavBar/NavBar'
import Footer from '../../_components/Footer/'

class MainLayout extends Component {
  render() {
    return (
      <>
        <NavBar />

        {this.props.children}

        <Footer />
      </>
    )
  }
}

export default MainLayout
