import React, { useEffect, useState } from 'react'
import { Image } from 'react-bootstrap'

import './TeamSlider.css'

export default function TeamMember({ img, name, title, status }) {
  const [statusClass, setStatusClass] = useState()

  useEffect(() => {
    if (status === 'team__member--hidden') {
      setStatusClass('team__member--hidden-end')
      setTimeout(() => {
        setStatusClass('team__member--hidden-start')
      }, 2000)
    } else {
      setStatusClass(status)
    }
  }, [status])

  return (
    <div className={`team__member ${statusClass} text-center`}>
      <Image src={img} className='team__member--image' />
      <div className='team__member--info'>
        <div className='pt-3 team__title'>{name}</div>
        <div className='pt-2 team__subtitle'>{title}</div>
      </div>
    </div>
  )
}
