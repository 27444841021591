import React from 'react'
import { Link } from 'react-router-dom'
import { Col, Row, Image, Navbar } from 'react-bootstrap'

import logo from '../../_assets/Shared/logo@3x.png'
import appStore from '../../_assets/Shared/app-store-badge.svg'
import googleStore from '../../_assets/Shared/google-play-badge.png'

import './Footer.css'

export default function Footer() {
  return (
    <div className='axs__footer container mx-auto'>
      <Row className='axs__footer--info'>
        <Col sm={12} lg={5}>
          <Navbar.Brand as={Link} to='/home'>
            <Image src={logo} className='axs__footer--logo' />
          </Navbar.Brand>
        </Col>
        <Col sm={12} lg={7}>
          <Row className='pl-sm-4 pt-4'>
            <Col
              xs={{ order: 'second' }}
              sm={{ span: 3, order: 'first' }}
              md={3}
              lg={3}
              className='axs__footer__clients px-sm-0'
            >
              <Row>
                <Col>CLIENTS</Col>
              </Row>
              <Row>
                <Col className='axs__footer__clients__list'>
                  <Link
                    as='text'
                    className='axs__footer__client'
                    to='/solutions'
                  >
                    Medical Office
                  </Link>
                  <br />
                  <Link
                    as='text'
                    className='axs__footer__client'
                    to='/solutions/telehealth'
                  >
                    Surgery Center
                  </Link>
                  <br />
                  <Link
                    as='text'
                    className='axs__footer__client'
                    to='/solutions/messages'
                  >
                    Hospital
                  </Link>
                  <br />
                </Col>
              </Row>
            </Col>
            <Col
              xs={{ order: 'first' }}
              sm={{ span: 6, order: 'second' }}
              md={6}
              lg={5}
              className='axs__footer__contact px-md-5 px-lg-2'
            >
              <Row>
                <Col>CONTACT US</Col>
              </Row>
              <Row>
                <Col>
                  <br />
                  <Row>
                    <Col>
                      <a
                        rel='noopener noreferrer'
                        href='https://goo.gl/maps/HAEhYPeRRbcdDCa79'
                        target='_blank'
                        className='axs__footer__contact__address'
                      >
                        1901 W. Lugonia Ave Suite 340 Redlands, CA 92374
                      </a>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <a
                        href='mailto:info@axshealthapp.com'
                        className='axs__footer__email'
                      >
                        info@axshealthapp.com
                      </a>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col sm={1} md={2} lg={3} className='axs__footer__download px-sm-0'>
              <Row>
                <Col>DOWNLOAD</Col>
              </Row>
              <Row className='pt-4 pt-sm-3'>
                <Col>
                  <Image
                    src={appStore}
                    width='127'
                    onClick={() => {
                      window.open(
                        'https://link.axshealthapp.com/9vojavdnFob',
                        '_blank'
                      )
                    }}
                    className='cursor-pointer'
                  />
                </Col>
              </Row>
              <Row>
                <Col className='pl-1'>
                  <Image
                    src={googleStore}
                    width='147'
                    height='63'
                    onClick={() => {
                      window.open(
                        'https://link.axshealthapp.com/9vojavdnFob',
                        '_blank'
                      )
                    }}
                    className='cursor-pointer'
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col className='axs__footer__copy'>
          <hr />
          <div className='pt-2'>
            &copy; 2022 <Link to='/home'>AxS Health</Link>. All Rights Reserved.
            <Link to='/terms-of-use'>Terms &amp; Privacy</Link>.
          </div>
        </Col>
      </Row>
    </div>
  )
}
