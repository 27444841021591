import React, { useEffect } from 'react'
import FormsImg from '../../_assets/Shared/MacbookForms@3x.png'
import EventsImg from '../../_assets/Shared/MacbookEvents@3x.png'
import MessagesImg from '../../_assets/Shared/MacbookMessages@3x.png'
import TelehealthImg from '../../_assets/Shared/MacbookTelehealth@3x.png'

import {
  scrollToSection,
  scrollInSolutions
} from '../../_helpers/handleAnimation'

import { useParams, useHistory } from 'react-router-dom'
import { Row, Col, Image, Container } from 'react-bootstrap'

export default function Solutions() {
  const history = useHistory()
  const yOffset = window.innerWidth < 992 ? -20 : 20
  const { section } = useParams()

  useEffect(() => {
    window.scroll(0, 0)
    window.addEventListener('scroll', scrollInSolutions)
    const unlisten = history.listen(onRouteChange)

    return () => {
      unlisten()
      window.removeEventListener('scroll', scrollInSolutions)
    }
  }, [])

  useEffect(() => {
    document
      .getElementById('documents--image')
      .classList.add('solution--image-animate')
    if (section) scrollToSection(section, yOffset)
  }, [])

  const onRouteChange = (route) => {
    if (route.pathname.includes('/services')) {
      switch (route.pathname) {
        case '/services/telehealth':
          scrollToSection('telehealth', yOffset)
          break
        case '/services/messages':
          scrollToSection('messages', yOffset)
          break
        case '/services/forms':
          scrollToSection('forms', yOffset)
          break
        case '/services/documents':
          window.scroll({ top: 10, left: 0, behavior: 'auto' })
          break
        case '/services':
          window.scroll({ top: 0, left: 0, behavior: 'auto' })
          break
        default:
          break
      }
    }
  }

  return (
    <Container fluid>
      <Row>
        <Col className='px-0'>
          <div className='solution--header text-center p-4'>
            <h1 className='m-0'>
              The right AxS <span className='text-primary'>Solution</span>
            </h1>
          </div>
        </Col>
      </Row>

      <Row>
        <Col className='px-0'>
          <div id='documents-section' className='solution'>
            <div className='container text-center mx-auto'>
              <Row>
                <Col
                  xs={{ span: 12, order: 'last' }}
                  lg={{ span: 4, order: 'first' }}
                  className='text-left'
                >
                  <Row>
                    <Col className='solution__title'>Digital Documents</Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className='solution__info--title'>Description</div>
                      <hr />
                      <div className='solution__info--description mb-4'>
                        A digital document is any written communication that
                        your organization can send to a patient.
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className='solution__info--title'>
                        Patient Action
                      </div>
                      <hr />
                      <div className='solution__info--description mb-4'>
                        Your organization can determine the type of patient
                        digital response required for each digital document.
                        Patient responses include: Completion of the information
                        requested on the document or form, Digital Consent, and
                        Patient acknowledgement of receiving the information.
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className='solution__info--title'>Tracking</div>
                      <hr />
                      <div className='solution__info--description mb-4'>
                        Automatic reminders to the patient and status updates on
                        the organization dashboard provide ease of tracking.
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col
                  xs={{ span: 12, order: 'first' }}
                  lg={{ span: 8, order: 'last' }}
                >
                  <Image
                    id='documents--image'
                    src={FormsImg}
                    fluid
                    className='solution--image'
                  />
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col className='px-0'>
          <div id='telehealth-section' className='solution--reversed'>
            <div className='container text-center mx-auto'>
              <Row style={{ flexDirection: 'row-reverse' }}>
                <Col
                  xs={{ span: 12, order: 'last' }}
                  lg={{ span: 4, order: 'first' }}
                  className='text-left'
                >
                  <Row>
                    <Col className='solution__title'>Telehealth</Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className='solution__info--title'>Description</div>
                      <hr />
                      <div className='solution__info--description mb-4'>
                        Patients and Providers benefit from secure telehealth.
                        Use real-time video or text based telehealth for
                        physician visits, nurse call backs, pre-op/post-op
                        assessments, case management, and therapy visits.
                        <br />
                        <br />
                        During or after the telehealth visit, the provider can
                        securely send the care plan, status assessment, patient
                        instruction, exercise/therapy plan, or any other pdf
                        document to the patient for reference by the patient at
                        any time.
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className='solution__info--title'>
                        Real-Time Video
                      </div>
                      <hr />
                      <div className='solution__info--description mb-4'>
                        This is a video face-to-face encounter between the
                        provider and patient, augmented by digital information
                        provided by the patient prior to the visit.
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className='solution__info--title'>
                        Asynchronous Telehealth
                      </div>
                      <hr />
                      <div className='solution__info--description mb-4'>
                        This is a text based encounter between the provider and
                        patient, augmented by digital information provided by
                        the patient prior to the visit. A text based visit can
                        be converted to a real-time video visit during the
                        telehealth appointment.
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col
                  xs={{ span: 12, order: 'first' }}
                  lg={{ span: 8, order: 'last' }}
                >
                  <Image
                    src={TelehealthImg}
                    fluid
                    className='reversed solution--image'
                  />
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col className='px-0'>
          <div id='messages-section' className='solution'>
            <div className='container text-center mx-auto'>
              <Row>
                <Col
                  xs={{ span: 12, order: 'last' }}
                  lg={{ span: 4, order: 'first' }}
                  className='text-left'
                >
                  <Row>
                    <Col className='solution__title'>Messages</Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className='solution__info--title'>Description</div>
                      <hr />
                      <div className='solution__info--description mb-4'>
                        Accessing a healthcare provider by phone is often a
                        dissatisfying process for patients. Messaging provides
                        secure communication with patients that reduces the need
                        for telephone calls.
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className='solution__info--title'>
                        Patient Initiated
                      </div>
                      <hr />
                      <div className='solution__info--description mb-4'>
                        Secure messaging to and from patients provides a
                        convenient way for patients to convey their questions
                        and receive timely answers. In addition to text-based
                        information, patients can message photos, lab reports,
                        insurance card information or any PDF document.
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className='solution__info--title'>
                        Provider Initiated
                      </div>
                      <hr />
                      <div className='solution__info--description mb-4'>
                        Providers use messaging for patients to send information
                        such as appointment times, referral/authorization
                        status, instructions, treatment care plans, workers
                        compensation status reports, images, weblinks, or any
                        pdf document.
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col
                  xs={{ span: 12, order: 'first' }}
                  lg={{ span: 8, order: 'last' }}
                >
                  <Image src={MessagesImg} fluid className='solution--image' />
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col className='px-0'>
          <div id='events-section' className='solution--reversed'>
            <div className='container text-center mx-auto'>
              <Row style={{ flexDirection: 'row-reverse' }}>
                <Col
                  xs={{ span: 12, order: 'last' }}
                  lg={{ span: 4, order: 'first' }}
                  className='text-left'
                >
                  <Row>
                    <Col className='solution__title'>Care Events</Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className='solution__info--title'>Description</div>
                      <hr />
                      <div className='solution__info--description mb-4'>
                        With Events, your organization can schedule all
                        communication (forms, telehealth visits, instructions,
                        assessments) related to a care event such as surgery
                        date.
                        <br />
                        <br />
                        All points of patient contact are scheduled upon surgery
                        scheduling, and the Program/Dept can assign the date of
                        delivery to the patient, allowing information such as
                        outcomes surveys to be scheduled now and delivered to
                        the patient a year from the care event date. If the care
                        event changes, the system automatically updates the
                        patient completion due dates to correspond with the care
                        event change.
                        <br />
                        <br />
                        Delivery and tracking is automatic, reducing staff time
                        for tracking information and providing continuity of
                        communication even with staff changes.
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col
                  xs={{ span: 12, order: 'first' }}
                  lg={{ span: 8, order: 'last' }}
                >
                  <Image
                    src={EventsImg}
                    fluid
                    className='reversed solution--image'
                  />
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>

      <Row className='mb-5'>
        <Col className='px-0'>
          <div id='forms-section' className='solution'>
            <div className='container text-center mx-auto'>
              <Row>
                <Col
                  xs={{ span: 12, order: 'last' }}
                  lg={{ span: 4, order: 'first' }}
                  className='text-left'
                >
                  <Row>
                    <Col className='solution__title'>Forms</Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className='solution__info--title'>Description</div>
                      <hr />
                      <div className='solution__info--description mb-4'>
                        AxS Health provides our client the ability to customize
                        digital forms that are sent in a secure manner supported
                        by analytics and automation.
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className='solution__info--title'>Form Builder</div>
                      <hr />
                      <div className='solution__info--description mb-4'>
                        You can select forms from the AxS template library. You
                        can customize these templates by creating and adding new
                        questions or create an entirely new logo branded form
                        for your organization.
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className='solution__info--title'>Download</div>
                      <hr />
                      <div className='solution__info--description mb-4'>
                        Patient completed forms can be downloaded/printed as a
                        word document or pdf for easy use within any
                        organization, regardless of EHR.
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col
                  xs={{ span: 12, order: 'first' }}
                  lg={{ span: 8, order: 'last' }}
                >
                  <Image src={FormsImg} fluid className='solution--image' />
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
      <br />
    </Container>
  )
}
