export const scrollInPatientDetails = () => {
  const viewTheshold = window.innerWidth < 992 ? 4000 : 2800
  const dots = document.getElementsByClassName('for__patient__dot--container')
  const centerImg = document.getElementsByClassName('for__patient--dashboard')
  const background = document.getElementById('for__patient__section')

  if (dots.length > 0) {
    for (const dot of dots) {
      const currentScroll = window.pageYOffset

      if (currentScroll >= viewTheshold) {
        dot.classList.add('for__patient__dot--animate')
        centerImg[0].classList.add('for__patient--dashboard-animate')
        background.classList.add('for__patient--animate')
      }
    }
  }
}

export const scrollInForProviderDetails = () => {
  const details = document.getElementsByClassName('for__providers--details')

  if (details.length > 0) {
    for (const detail of details) {
      if (detail.getBoundingClientRect().top <= 600) {
        detail.classList.add('for__providers--details-animate')
      }
    }
  }
}

export const scrollInSolutions = () => {
  const solutions = document.getElementsByClassName('solution--image')

  if (solutions.length > 0) {
    for (const solution of solutions) {
      if (solution.getBoundingClientRect().top <= 500) {
        solution.classList.add('solution--image-animate')
      }
    }
  }
}

export const scrollToSection = (section, yOffset, behavior = 'auto') => {
  const id = `${section}-section`
  const element = document.getElementById(id)
  const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset

  window.scrollTo({ top: y, behavior: behavior })
}
