import React from 'react'
import { Route } from 'react-router-dom'

const PublicRoute = ({
  component: Component,
  layout: Layout,
  props: cProps,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout>
          <Component {...props} {...cProps} />
        </Layout>
      )}
    />
  )
}

export default PublicRoute
