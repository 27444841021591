import React from 'react'

export default function ForPatientsDetail(props) {
  return (
    <div
      className={`for__patient__dot--container ${props.align}`}
      style={
        props.align === 'for__patient__dot--right'
          ? { transform: 'translateX(-5em)' }
          : { transform: 'translateX(5em)' }
      }
    >
      <span className={`for__patient__dot for__patient__dot--${props.dot}`} />
      <div className='for__patient__dot--title'>{props.title}</div>
      <div className={`for__patient__dot--detail ${props.align}`}>
        {props.detail}
      </div>
    </div>
  )
}
